import Button from "../../../web-components/src/atoms/interact/Button";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import { CreateMonitorModal } from "components/monitors/CreateMonitorModal";
import { useState } from "react";

export function SidebarCreateMonitorButton({}): JSX.Element {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  return (
    <>
      <div className="w-full bg-white rounded">
        <Button full onClick={() => setModalVisible(true)} type="SECONDARY" LeadIcon={PlusSmallIcon}>
          New Monitor
        </Button>
      </div>
      <CreateMonitorModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
    </>
  );
}
