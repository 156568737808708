import { AssigneeOptionGroup } from "@avenueops/shared-types";
import { DynamicStatus, ProcessStatus } from "@prisma/client";
import _ from "lodash";

function setStatusArgs(statuses: DynamicStatus[] | "STATIC") {
  window.CommandBar?.addArgumentChoices("signalStatusChoices", () => {
    if (statuses === "STATIC") return Object.keys(ProcessStatus).map((key) => ({ label: _.capitalize(key), value: key }));

    // Use dynamic statuses if available, else fallback to ProcessStatus
    return statuses.map((status) => ({
      label: status.label,
      value: status.id,
    }));
  });
}

function setAssigneeArgs(assigneeOptionGroups: AssigneeOptionGroup[] | undefined) {
  window.CommandBar?.addArgumentChoices("signalAssigneeChoices", () => {
    if (!assigneeOptionGroups) return [];
    return assigneeOptionGroups.flatMap((optionGroup) => {
      return optionGroup.options;
      // Filed some bugs w/ CommandBar argument icons here: https://useavenuehq.slack.com/archives/C01GRLF2EH5/p1675967373416269
      // Will hold off on icons for now.
      // return optionGroup.options.map((option) => {
      //   if (option.value.teamId !== undefined) {
      //     const label = option.metadata?.team.name;

      //     return { ...option, label, icon: option.emoji };
      //   }

      //   return { ...option, icon: option.iconUrl };
      // })
    });
  });
}

export const processes = {
  setStatusArgs,
  setAssigneeArgs,
} as const;
