/**
 * All of the content for a sidebar link
 */
export type SidebarLinkData = {
  href: string;
  title: string;
  icon: React.FC<any>;
  titleBadge?: string;
  betaTag?: boolean;
  subItems?: SubSidebarItemData[];
  type: SideBarItemType.LINK;
};

/**
 * Slightly altered link data for a sub sidebar link
 */
export type SubSidebarLinkData = { href: string; title: string; betaTag?: boolean; type: SideBarItemType.LINK };

/**
 * All of the content for a sidebar button
 */
export type SidebarButtonData = {
  title: string;
  icon: React.FC<any>;
  onClick: () => void;
  type: SideBarItemType.BUTTON;
};

/**
 * Union types for what can be in a sidebar item
 */
export type SidebarItemData = SidebarLinkData | SidebarButtonData;
export type SubSidebarItemData = SubSidebarLinkData | SidebarButtonData;

/**
 * A wrapper for the data inside a sidebar section
 */
export type SidebarSectionData = { sectionTitle: string; items: SidebarItemData[] };

/**
 * The possible types for a sidebar item
 */
export enum SideBarItemType {
  BUTTON = "BUTTON",
  LINK = "LINK",
}
