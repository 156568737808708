import { useProcessTableState } from "./ProcessTableContext";
import { ArchivedFilter, ProcessCountGroup, ProcessFilterZod, ProcessGroup } from "@avenueops/shared-types";
import { InfiniteData } from "@tanstack/react-query";
import { filterHasError } from "components/ProcessTable/ProcessTableFilters/utils";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import { trpc } from "utils/trpc";

function getGroupToFilter(expandedGroups: string[], groupHeadersAndCount: ProcessCountGroup[] | undefined): any[] | undefined {
  if (!groupHeadersAndCount || !groupHeadersAndCount[0]) {
    return undefined;
  }
  switch (groupHeadersAndCount[0].groupByfilterType?.type) {
    case "ASSIGNEE":
      return expandedGroups
        .map((group) => {
          return groupHeadersAndCount.find((header) => header.value === group)?.groupByfilterType?.value;
        })
        .flat();

    case "STATUS":
      return expandedGroups.map((group) => {
        return groupHeadersAndCount.find((header) => header.value === group)?.groupByfilterType?.value;
      });

    case "WORKFLOW":
      return expandedGroups
        .map((group) => {
          return groupHeadersAndCount.find((header) => header.value === group)?.groupByfilterType?.value;
        })
        .flat();

    case "ESCALATION_GROUP":
      return expandedGroups
        .map((group) => {
          return groupHeadersAndCount.find((header) => header.value === group)?.groupByfilterType?.value;
        })
        .flat();
  }
  return undefined;
}

interface Props {
  archived: ArchivedFilter;
  isDashboard: boolean;
}

export function useProcessesForTable({ archived, isDashboard = false }: Props): {
  groupHeadersAndCount: ProcessCountGroup[] | undefined;
  processes: InfiniteData<ProcessGroup[]> | undefined;
  hasNextPage: boolean | undefined;
  isFetching: boolean | undefined;
  fetchNextPage: () => void;
  isLoading: boolean | undefined;
  filteredProcessGroups: ProcessGroup[];
} {
  // Get the Table State
  const [{ filters, groupBy, sort, search, timeFrame, timeFrameStartIncl, timeFrameEndExcl }] = useProcessTableState();
  const [debouncedSearch] = useDebounce(search, 500);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Make sure none of the filters have errors
  const refinedFilters = filters?.filter((filter: ProcessFilterZod) => !filterHasError(filter));

  // Fetch counts by group
  const { data: groupHeadersAndCount } = trpc.processes.countByGroup.useQuery({
    filters: refinedFilters ?? [],
    groupBy,
    // we don't need sort because it won't affect the count by group numbers
    timeFrame,
    search: debouncedSearch ?? undefined,
    timeFrameStartIncl: timeFrameStartIncl?.toString(),
    timeFrameEndExcl: timeFrameEndExcl?.toString(),
    timeZone,
    archived,
    isDashboard: isDashboard,
  });

  const { hasNextPage, isFetching, fetchNextPage, data, isLoading } = trpc.processes.list.useInfiniteQuery<ProcessGroup[]>(
    {
      filters: refinedFilters ?? [],
      groupBy,
      timeFrame,
      sort: sort ?? [],
      search: debouncedSearch ?? undefined,
      timeFrameStartIncl: timeFrameStartIncl?.toString(),
      timeFrameEndExcl: timeFrameEndExcl?.toString(),
      limit: 100,
      timeZone,
      archived: archived,
      isDashboard: isDashboard,
    },
    {
      getNextPageParam: (lastPage: any[]) => {
        return lastPage.length > 0 ? lastPage[lastPage.length - 1].nextCursor : undefined;
      },
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      refetchInterval: 15000,
      refetchIntervalInBackground: false,
    },
  );

  const filteredProcessGroups: ProcessGroup[] = useMemo(() => {
    const filteredProcessGroups: ProcessGroup[] =
      groupHeadersAndCount?.map((header): ProcessGroup => {
        return {
          icon: header.icon,
          title: header.groupByTitle ?? "",
          value: header.value,
          processes: [],
        };
      }) ?? [];
    if (!data || !data.pages || !data.pages[0]) {
      return filteredProcessGroups;
    }

    for (const page of data.pages) {
      for (const processGroup of page) {
        const group = filteredProcessGroups.find((group) => group.title === processGroup.title);
        if (group) {
          group.nextCursor = processGroup.nextCursor;
          group.processes = [...group.processes, ...processGroup.processes];
        }
      }
    }
    return filteredProcessGroups;
  }, [data, groupHeadersAndCount]);

  return {
    groupHeadersAndCount,
    processes: data,
    hasNextPage,
    isFetching,
    fetchNextPage, // global
    isLoading,
    filteredProcessGroups,
  };
}

export function useProcessTableRows({ archived, isDashboard }: { archived: ArchivedFilter; isDashboard: boolean }) {
  const { processes, groupHeadersAndCount } = useProcessesForTable({ archived, isDashboard });

  const processedData = useMemo(() => {
    if (!processes || !processes.pages || !processes.pages[0]) {
      return [];
    }

    const groupedProcesses: any[] = [];
    for (const page of processes.pages) {
      for (const processGroup of page) {
        const group = groupedProcesses.find((group) => group.title === processGroup.title);
        if (group) {
          group.processes = [...group.processes, ...processGroup.processes];
        } else {
          groupedProcesses.push({
            icon: processGroup.icon,
            title: processGroup.title,
            processes: [...processGroup.processes],
            value: processGroup.value,
            count: groupHeadersAndCount?.find((group) => group.groupByTitle === processGroup.title)?.count ?? 0,
          });
        }
      }
    }
    return groupedProcesses.flatMap((group) => [
      { header: { icon: group.icon, title: group.title, count: group.count }, ...group.processes[0] },
      ...group.processes,
    ]);
  }, [processes, groupHeadersAndCount]);

  return processedData;
}
