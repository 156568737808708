import { clearTableStateFromLocalStorage } from "../context/ProcessTableContext";
import { dashboardLocalStorageKey, signalsListLocalStorageKey } from "../context/ProcessTableContext";
import { BASE_API_URL } from "../utils/constants";
import { useOutsideClick } from "../utils/hooks";
import { Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useOrganization } from "context/OrganizationContext";
import { useUser } from "context/UserContext";
import Link from "next/link";
import React, { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";

export default function OrganizationsDropdown(): JSX.Element {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const currentOrganization = useOrganization();
  const user = useUser();
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setDropdownOpen(false));

  const { isLoading, data } = useQuery(["all_organization"], async () => {
    const response = await axios.get("/organizations/");
    return response.data;
  });

  React.useEffect(() => {
    if (!data || isLoading) return;

    window.CommandBar?.addContext(
      "organizations",
      data.map((organization: any) => {
        return {
          name: organization?.name,
          id: organization?.id,
          image: organization?.image,
        };
      }),
    );

    return () => {
      window.CommandBar?.removeContext("organizations");
    };
  }, [data, isLoading]);

  return (
    <div className="relative block text-left" ref={dropdownRef}>
      {/* <!-- Dropdown menu toggle, controlling the show/hide state of dropdown menu. --> */}
      <div className="relative">
        <Transition
          show={dropdownOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {(ref) => (
            <div
              ref={ref}
              className="absolute left-0 right-0 top-full lg:top-auto lg:bottom-full mb-2 w-full z-10 mt-1 origin-bottom bg-white rounded divide-y divide-gray-200 shadow-md ring-1 ring-black ring-opacity-5 max-h-[320px] overflow-auto"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="">
                {isLoading ? (
                  <Skeleton count={2} />
                ) : (
                  <div className="divide-y">
                    {data?.map((organization: any) => (
                      <button
                        key={organization?.id}
                        onClick={async () => {
                          const response = await axios.post(`${BASE_API_URL}/organizations/${organization?.id}`);
                          if (response.status === 200) {
                            location.reload();
                          }
                        }}
                        className="w-full"
                      >
                        <button
                          type="submit"
                          className="block w-full px-4 py-2 text-sm text-left text-gray-700 first:rounded-t hover:bg-cool-gray-50 hover:text-gray-900"
                          role="menuitem"
                          onClick={
                            organization?.id !== currentOrganization?.id
                              ? () => {
                                  clearTableStateFromLocalStorage(dashboardLocalStorageKey);
                                  clearTableStateFromLocalStorage(signalsListLocalStorageKey);
                                }
                              : () => undefined
                          }
                        >
                          <div>
                            <div>
                              <span className="font-bold">{organization?.name}</span>
                            </div>
                            <div className="text-xs">{organization?._count.organizationMemberships} Members</div>
                          </div>
                        </button>
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <div className="bg-white">
                <Link
                  href="/account/settings"
                  className=" block px-4 py-2 text-sm font-semibold leading-5 text-gray-700 hover:text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem"
                >
                  Settings
                </Link>
              </div>
              <div className="bg-cool-gray-50 rounded-b">
                <a
                  href={BASE_API_URL + "/logout"}
                  className=" block px-4 py-2 text-sm font-semibold leading-5 text-gray-700 rounded-b hover:text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem"
                  onClick={() => {
                    clearTableStateFromLocalStorage(dashboardLocalStorageKey);
                    clearTableStateFromLocalStorage(signalsListLocalStorageKey);
                  }}
                >
                  Log out
                </a>
              </div>
            </div>
          )}
        </Transition>

        <button
          className="w-full py-2 px-2 border border-gray-300 rounded text-gray-600 bg-white"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
        >
          <span className="flex items-center justify-between w-full">
            <div className="flex items-center justify-between min-w-0 space-x-3">
              <div>
                <div
                  data-test="user-image"
                  className="flex text-sm transition duration-150 ease-in-out focus:outline-none focus:border-gray-300 active:border-gray-300 border-cool-gray-300"
                >
                  <img className="w-9 h-9" src={user.image ?? undefined} alt={`${user.name}`} />
                </div>
              </div>
              <div className="flex flex-col flex-1 min-w-0 items-start text-left">
                <span data-for={"org_name"} data-tip={currentOrganization?.name} data-place="top" className="text-sm font-semibold w-full truncate">
                  {currentOrganization?.name}
                </span>
                <ReactTooltip id="org_name" className="!opacity-95" effect="solid" delayShow={500} backgroundColor={"#6b7280"} />
                <span className="text-xs font-medium truncate w-full">{user.email}</span>
              </div>
            </div>
          </span>
        </button>
      </div>
    </div>
  );
}
