import { GroupBy } from "@avenueops/shared-types";
import { ProcessFilterZodArray } from "@avenueops/shared-types";
import { ClientTag, ClientWorkflow, ProcessFilterZod, ProcessFilterType } from "@avenueops/shared-types";
import { MonitorGroup, ProcessTimeFrame } from "@prisma/client";
import { stringifyTimeFrame } from "components/generic-building-blocks/ProcessTimeFrameSelector";
import { TimeFrameArgs } from "context/ProcessTableContext";
import _ from "lodash";
import { emojify } from "node-emoji";

/**
 * Validates contents of the filter to make sure it's properly configured
 */
export function filterHasError(filter: ProcessFilterZod) {
  if (!filter || filter.value === undefined) {
    return true;
  }
  if (filter.type === "STATUS") {
    return filter.value.length === 0;
  } else if (filter.type === "PRIORITY") {
    return filter.value.length === 0;
  } else if (filter.type === "WORKFLOW") {
    return filter.value.length === 0;
  } else if (filter.type === "ESCALATION_GROUP") {
    if (filter.value === "ESCALATED") return false;
    if (filter.value.length === 0) return true;
    for (const level of filter.value) {
      if (isNaN(parseInt(level as unknown as string))) return true;
    }
    return false;
  } else if (filter.type === "ASSIGNEE") {
    return !filter.value;
  } else if (filter.type === "TIME_TO_RESOLUTION") {
    return isNaN(parseInt(filter.value as unknown as string)) || !filter.relation || !filter.secondValue;
  } else if (filter.type === "TAG") {
    return filter.value.length === 0;
  } else if (filter.type === "MONITOR_GROUP") {
    return filter.value.length === 0;
  } else if (filter.type === "MONITOR_OWNER") {
    return filter.value.length === 0;
  } else if (filter.type === "COMPLETED_AT") {
    return !filter.relation || !filter.value || (filter.relation === "BETWEEN" && !filter.secondValue);
  } else if (filter.type === "CREATED_AT") {
    return !filter.value || (filter.value === "CUSTOM" && (!filter.customStart || !filter.customEnd));
  } else {
    return true;
  }
}

/**
 * Generates the type name as seen in the pill string
 */
export function stringifyFilterType(filterType: ProcessFilterType) {
  if (filterType === "WORKFLOW") {
    return "Monitor";
  } else if (filterType === "ESCALATION_GROUP") {
    return "Escalation Group Level";
  } else if (filterType === "ASSIGNEE") {
    return "Assignee";
  } else if (filterType === "TIME_TO_RESOLUTION") {
    return "Time To Resolution";
  } else if (filterType === "MONITOR_GROUP") {
    return "Monitor Group";
  } else if (filterType === "MONITOR_OWNER") {
    return "Monitor Owner";
  } else if (filterType === "COMPLETED_AT") {
    return "Completed At";
  } else if (filterType === "CREATED_AT") {
    return "Created At";
  } else {
    return _.startCase(_.toLower(_.replace(filterType, "_", " ")));
  }
}

/**
 * Generates the value name as seen in the pill string
 */
export function stringifyFilterValue(
  filter: ProcessFilterZod,
  workflowOptions: ClientWorkflow[] | undefined,
  tagOptions: ClientTag[] | undefined,
  monitorGroups: MonitorGroup[] | undefined
) {
  if (filter.type === "WORKFLOW") {
    if (workflowOptions) {
      const filteredWorkflows = workflowOptions.filter((workflow) => filter.value?.includes(workflow.id!));
      if (filteredWorkflows.length === 0) {
        return "";
      } else {
        if (filteredWorkflows.length === 1) {
          return filteredWorkflows[0].title;
        }
        return filteredWorkflows.map((workflow) => `${emojify(workflow.emoji!)}`).join("");
      }
    } else {
      return "";
    }
  } else if (filter.type === "ASSIGNEE") {
    if (!filter?.value || filter.value.length === 0) {
      return "";
    }
    if (filter.value.length === 1) {
      return filter.value[0].label;
    }
    return "multiple assignees";
  } else if (filter.type === "TIME_TO_RESOLUTION") {
    if ((filter.value === 0 || filter.value) && filter.secondValue) {
      return `${filter.value} ${_.lowerCase(filter.secondValue)}`;
    } else {
      return "";
    }
  } else if (filter.type === "TAG") {
    if (tagOptions) {
      if (!filter?.value || filter.value.length === 0) {
        return "";
      }
      if (filter.value.length === 1) {
        const tag = tagOptions.find((tag) => filter.value?.includes(tag.id));
        return tag?.name;
      }
      return "multiple tags";
    } else {
      return "";
    }
  } else if (filter.type === "MONITOR_GROUP") {
    if (monitorGroups) {
      if (!filter?.value || filter.value.length === 0) {
        return "";
      }
      if (filter.value.length === 1) {
        const group = monitorGroups.find((group) => filter.value?.includes(group.id));
        return group?.title;
      }
      return "multiple monitor groups";
    } else {
      return "";
    }
  } else if (filter.type === "MONITOR_OWNER") {
    if (!filter?.value || filter.value.length === 0) {
      return "";
    }
    if (filter.value.length === 1) {
      const owner = filter.value[0].label;
      return owner;
    }
    return "multiple owners";
  } else if (filter.type === "STATUS") {
    if (!filter?.value || filter.value.length === 0) {
      return "";
    }
    if (filter.value && filter.value.length === 1) {
      if (filter.value[0] === "NOTIFICATION_ONLY") {
        return "Notification Only";
      } else {
        return _.capitalize(filter.value[0].toString());
      }
    }
    return "multiple statuses";
  } else if (filter.type === "PRIORITY") {
    if (!filter?.value || filter.value.length === 0) {
      return "";
    }
    if (filter.value && filter.value.length === 1) {
      if (filter.value[0] === "NO_PRIORITY") {
        return "No Priority";
      } else {
        return _.capitalize(filter.value[0].toString());
      }
    }
    return "multiple priorities";
  } else if (filter.type === "COMPLETED_AT") {
    if (filter.relation === "BEFORE" || filter.relation === "AFTER") {
      return _.toLower(filter.relation) + " " + filter.value;
    } else if (filter.relation === "BETWEEN") {
      return _.toLower(filter.relation) + " " + filter.value + " and " + filter.secondValue;
    } else {
      return "";
    }
  } else if (filter.type === "CREATED_AT") {
    return _.startCase((filter.value as string)?.replaceAll("_", " ").toLowerCase());
  } else {
    return _.capitalize(filter.value?.toString());
  }
}

export function stringifyFilterRelation(relation: string) {
  return `is ${_.lowerCase(relation.replaceAll("_", " "))}`;
}

export function stringifyCompletedAtFilterRelation(relation: string) {
  return _.capitalize(relation);
}

export function activeFiltersShortText(filters: ProcessFilterZodArray): string {
  let filterTypes: string[] = [...new Set(filters.map((filter: ProcessFilterZod) => filter.type))];

  return (
    "Filtered by " +
    filterTypes.reduce(
      (accumulator, currentValue) => accumulator + ", " + _.capitalize(_.replace(currentValue, "_", " ")),
      _.capitalize(_.replace(filterTypes.pop() ?? "", "_", " "))
    )
  );
}

export function getGroupbyShortText(groupBy: GroupBy): string {
  return "Grouped by " + stringifyFilterType(groupBy);
}
