import get from "../../utils/get";
import PopoverTooltip from "../generic-building-blocks/PopoverTooltip";
import React from "react";
import { v4 as uuidv4 } from "uuid";

interface Props {
  title?: string;
  titleExplainer?: "TELEGRAM_NOTIFICATION";
  register: { onChange: any; onBlur: any; name: any; ref: any };
  inputType?: string;
  className?: string;
  defaultValue?: string | number | null;
  placeholder?: string;
  errors?: Record<string, unknown>;
  hideErrorMessage?: boolean;
  disableFocus?: boolean;
  disabled?: boolean;
  prefix?: string;
  suffix?: string;
  subTitle?: string;
  syntaxHighlight?: {
    highlight: (value: string) => string | React.ReactNode;
    watch: Function;
  };
}

export default function InputField({
  title,
  titleExplainer,
  register,
  inputType = "text",
  className,
  defaultValue,
  placeholder,
  errors,
  hideErrorMessage,
  disabled,
  prefix,
  suffix,
  subTitle,
  disableFocus,
  syntaxHighlight,
}: Props): JSX.Element {
  const highlightRendererRef = React.useRef<HTMLDivElement>(null);
  const formInputId = React.useMemo(() => {
    return uuidv4();
  }, []);

  const error = errors && get(errors, register.name);
  let syntaxHighLightValRaw = syntaxHighlight?.watch(register.name);
  const syncScroll: React.UIEventHandler<HTMLInputElement> = (e) => {
    if (highlightRendererRef.current) {
      highlightRendererRef.current.scrollTop = (e.target as HTMLInputElement).scrollTop;
      highlightRendererRef.current.scrollLeft = (e.target as HTMLInputElement).scrollLeft;
    }
  };
  return (
    <div>
      {!hideErrorMessage || !!title ? (
        <label htmlFor={formInputId} className={`mb-1 flex text-sm font-medium leading-5 text-gray-700 ${!title ? "flex-row-reverse" : "gap-x-2"}`}>
          {title ?? null}
          {title && titleExplainer === "TELEGRAM_NOTIFICATION" ? (
            <PopoverTooltip>
              <div className="w-64">
                Add the&nbsp;
                <a className="underline text-gray-800" href={"https://t.me/avenueappbot"}>
                  @AvenueAppBot
                </a>
                &nbsp;to your Telegram chat, send the "/start" command, and paste the chat ID that it sends you below.
              </div>
            </PopoverTooltip>
          ) : null}
          {hideErrorMessage ? null : error ? (
            error.message ? (
              <span className="text-red-500 ">{error.message}</span>
            ) : (
              <span className="text-red-500 ">required</span>
            )
          ) : null}
        </label>
      ) : null}
      {!!subTitle && (
        <label htmlFor={formInputId} className="mb-1 block text-sm leading-5 text-gray-500">
          {subTitle}
        </label>
      )}
      <div className="flex w-full flex-row items-center">
        {!!prefix && <div className="mr-1 block text-sm font-medium leading-5 text-gray-700">{prefix}</div>}
        <div className={`w-full ${syntaxHighlight ? "h-9 relative" : ""}`}>
          <input
            {...register}
            placeholder={placeholder}
            defaultValue={defaultValue ?? undefined}
            type={inputType}
            id={formInputId}
            disabled={disabled}
            onScroll={syncScroll}
            className={`
            form-input block
            w-full rounded
            border border-gray-300 px-3
            py-2 shadow-sm          
            transition duration-150 ease-in-out disabled:cursor-not-allowed
            ${error ? "border-red-200 outline-none ring ring-red-200 ring-opacity-50" : ""}
            focus:outline-none
            ${disableFocus ? "focus:border-gray-300 focus:ring-0" : "focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"}
            text-sm leading-5
            ${className}
            ${
              syntaxHighlight && syntaxHighLightValRaw != ""
                ? "z-10 [-webkit-text-fill-color:transparent] absolute bg-transparent"
                : "disabled:bg-gray-100 disabled:text-gray-700 disabled:shadow-inner"
            }
            `}
          />
          {syntaxHighlight && syntaxHighLightValRaw ? (
            <div
              ref={highlightRendererRef}
              className="absolute z-0 select-none flex overflow-x-auto px-3 py-2 text-sm leading-5 inset-0 whitespace-pre disabled:bg-gray-100 disabled:text-gray-700 disabled:shadow-inner "
            >
              {syntaxHighlight.highlight(syntaxHighLightValRaw)}
            </div>
          ) : null}
        </div>
        {!!suffix && <div className="ml-1 block text-sm font-medium leading-5 text-gray-700">{suffix}</div>}
      </div>
    </div>
  );
}
