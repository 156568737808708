import { COMMANDBAR_ORG_ID } from "../utils/constants";
import { ClientUser } from "@avenueops/shared-types";
import { OvalLoader } from "@avenueops/web-components";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { commandBar } from "utils/commandbar";
import { INTERCOM_ENABLED } from "utils/constants";
import { trpc } from "utils/trpc";

const UserContext = React.createContext<ClientUser | undefined>(undefined);

function UserProvider(props: any) {
  const {
    isLoading: orgLoading,
    error: orgErr,
    data: orgData,
  } = useQuery(["organization"], async () => {
    const response = await axios.get("/organizations/active");
    return response.data;
  });
  const { isLoading, error, data } = trpc.users.context.useQuery();
  const [commandBarReady, setCommandBarReady] = useState(false);
  const processCallbacks = commandBar.callbacks.processes.useCallbacks();

  const router = useRouter();

  // Initialize CommandBar and Intercom
  React.useEffect(() => {
    if (isLoading || !data) return;

    window?.CommandBar?.boot(data.id).then(() => {
      setCommandBarReady(true);
    });
    window?.CommandBar?.addRouter(router.push);
  }, [data, isLoading, router, orgData]);

  // Initialize CommandBar callbacks
  React.useEffect(() => {
    if (!commandBarReady) return;

    window.CommandBar?.addCallbacks(processCallbacks);
  }, [commandBarReady, processCallbacks]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen h-available bg-cool-gray-100">
        <OvalLoader type="DEFAULT" size={72} />
      </div>
    );
  }

  return <UserContext.Provider value={error ? null : data} {...props} />;
}

function useUser(): ClientUser {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUser };
