import { useSetSidebarOpen } from "context/SidebarContext";
import Link from "next/link";
import React from "react";

interface Props {
  href: string;
  className?: string;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  itemSelected: boolean;
}

export default function ActiveLink({ href, children, className, onClick, itemSelected }: Props): JSX.Element {
  const setSidebarOpen = useSetSidebarOpen();

  let newClassName;
  const baseClasses = "py-2 rounded-md flex items-center justify-start space-x-2 text-base font-medium transition duration-150 ease-in-out";
  if (itemSelected) {
    newClassName = `${className} ${baseClasses} text-gray-800 hover:text-primary-darker focus:outline-none bg-cool-gray-200 focus:text-gray-800`;
  } else {
    newClassName = `${className} ${baseClasses} text-gray-600 hover:text-gray-800 focus:outline-none hover:bg-cool-gray-150 focus:text-gray-800 focus:bg-cool-gray-200`;
  }

  const clickLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setSidebarOpen(false);
  };

  return (
    <Link href={href} onClick={onClick ?? clickLink} className={newClassName}>
      {children}
    </Link>
  );
}
