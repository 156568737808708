import BreadCrumb from "./Breadcrumb";
import Navbar from "./Navbar";
import { PageHead } from "./PageHead";
import SignIn from "./SignIn";
import Sidebar from "./sidebar/Sidebar";
import { Transition } from "@headlessui/react";
import { BillingPlan, BillingPlanStatus } from "@prisma/client";
import { useBillingPlan, useMembership, useOrganization } from "context/OrganizationContext";
import { useSidebarOpen } from "context/SidebarContext";
import { useUser } from "context/UserContext";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { ReactNode, useEffect } from "react";
import { ComponentType } from "react";
import { useIntercom } from "react-use-intercom";
import { pagesPath } from "utils/$path";
import { INTERCOM_ENABLED } from "utils/constants";

type Props = {
  children?: ReactNode;
  title?: string;
  description?: string;
  Icon?: React.FC<any>;
  headTitle?: string;
  backTo?: { href: string; title: string; Icon?: React.FC<any> }[];
  secondaryElement?: ReactNode;
  fullScreen?: boolean;
  hideSidebar?: boolean;
  tags?: ReactNode[];
  backgroundColor?: string;
  allowBlocked?: boolean;
  overflow?: "SCROLL" | "NO_SCROLL";
};

const AnimatedSidebar = () => {
  const sidebarOpen = useSidebarOpen();
  return (
    <>
      <div className="basis-[248px] w-[248px] flex-shrink-0 hidden lg:block relative bg-background-light ">
        <div className="h-full">
          <Sidebar />
        </div>
      </div>
      <Transition
        show={sidebarOpen}
        enter="ease-normal duration-200"
        enterFrom="lg:-ml-[248px]"
        enterTo="lg:ml-0"
        leave="ease-normal duration-200"
        leaveFrom="lg:ml-0"
        leaveTo="lg:-ml-[248px]"
        className="basis-[248px] z-40 lg:basis-auto absolute block lg:hidden bottom-0 top-[50px] w-[248px] lg:relative flex-shrink-0 overflow-scroll"
      >
        <Transition.Child
          enter="ease-normal duration-200"
          enterFrom="-translate-x-[248px] lg:translate-x-0"
          enterTo="translate-x-0"
          leave="ease-normal duration-200"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-[248px] lg:translate-x-0"
          className="z-50 relative h-full bg-background-light "
        >
          <Sidebar />
        </Transition.Child>
        <Transition.Child
          enter="ease-normal duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-normal duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed lg:hidden z-40 inset-0 bg-background-light/25 backdrop-blur-md transition-opacity" />
        </Transition.Child>
      </Transition>
    </>
  );
};

export default function AuthenticatedLayout({
  children,
  title,
  description,
  Icon,
  headTitle,
  backTo = [],
  secondaryElement,
  fullScreen,
  hideSidebar,
  tags,
  backgroundColor,
  overflow = "SCROLL",
  allowBlocked = false,
}: Props): JSX.Element | null {
  const user = useUser();
  const membership = useMembership();
  const billingPlan = useBillingPlan();
  const organization = useOrganization();
  const router = useRouter();
  const { boot } = useIntercom();

  useEffect(() => {
    if (INTERCOM_ENABLED && !!user) {
      boot({
        name: user.name,
        email: user.email,
        userId: user.id,
        userHash: user.intercomHash,
        hideDefaultLauncher: false,
      });
    }
  }, [boot, organization, user]);

  if (!user) {
    if (!router.asPath.includes("dashboard") && !router.asPath.includes("signin")) {
      sessionStorage.setItem("redirectTo", router.asPath);
    }
    return <SignIn isSignUp={false} />;
  } else if (sessionStorage.getItem("redirectTo")) {
    const redirectTo = sessionStorage.getItem("redirectTo");
    sessionStorage.removeItem("redirectTo");
    router.push(`/${redirectTo}`);
    return null;
  }

  if (!organization?.onboarded || !membership) {
    router.push("/onboarding");
    return null;
  }

  const isBlocked = membership.role === "BLOCKED" || (billingPlan && billingPlan.status !== BillingPlanStatus.ACTIVE);

  return (
    <div className={`w-full h-full relative ${backgroundColor ?? "bg-background-light"}`}>
      <PageHead title={headTitle ? headTitle : title} />
      {!hideSidebar ? <Navbar /> : null}
      <div className="w-full h-full relative flex">
        {!hideSidebar ? <AnimatedSidebar /> : null}
        {!isBlocked || allowBlocked ? (
          <div
            id="section-body"
            className={`flex flex-col ${fullScreen ? `${!hideSidebar ? "pt-[71px]" : ""} lg:pt-0` : "pt-[111px] lg:py-7"} mx-auto w-full ${
              overflow === "NO_SCROLL" ? "" : "overflow-auto"
            } flex-grow"`}
          >
            <header>
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex items-start">
                  <div className="flex items-center">
                    <BreadCrumb backTo={backTo} title={title} description={description} Icon={Icon} />
                  </div>

                  <div className="ml-auto flex flex-row gap-2 items-center">
                    {tags?.map((tag, index) => <div key={index}>{tag}</div>)}
                    {secondaryElement}
                  </div>
                </div>
              </div>
            </header>
            <main className="flex-grow">
              <div className={`${fullScreen ? "h-full" : "px-4 sm:px-6 lg:px-8"}`}>
                <div className={`${fullScreen ? "h-full" : "py-8"} relative`}>{children}</div>
              </div>
            </main>
          </div>
        ) : (
          <div className={`flex items-center justify-center w-full h-full my-auto`}>
            <div className="flex flex-col items-center justify-center h-64 p-8 space-y-4 text-2xl bg-white rounded border shadow-sm w-128">
              <PermissionError billingPlan={billingPlan} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function PermissionError({ billingPlan }: { billingPlan: BillingPlan | undefined }): JSX.Element {
  const { showNewMessage } = useIntercom();
  let title,
    description: JSX.Element | null = null;
  if (billingPlan && billingPlan.status !== BillingPlanStatus.ACTIVE) {
    let prefix: string;
    let suffix: string = "";
    if (billingPlan.status === BillingPlanStatus.CANCELED) {
      title = "🚨 Subscription Canceled 🚨";
      prefix = "Your organization's plan has been canceled.";
    } else {
      title = "🚨 Organization Locked 🚨";
      prefix = "Your organization has been locked due to a failed payment.";
      suffix = "to update your payment method.";
    }
    description = (
      <>
        {prefix}
        <u
          className="mx-1 cursor-pointer"
          onClick={() => {
            showNewMessage("Hello, I have a question about billing.");
          }}
        >
          Contact support to reopen your account
        </u>
        {suffix}
      </>
    );
  }

  return (
    <>
      <div className="text-xl font-semibold">{title}</div>
      <p className="text-base text-center text-gray-700">{description}</p>
    </>
  );
}
