import { DecryptedConnection } from "@avenueops/shared-types";

interface InputOption {
  title: string;
  label: string;
  value: string;
}

export function getConnectionChoices(connections: DecryptedConnection[]): InputOption[] {
  const choices: InputOption[] = [];
  connections.map((connection) => {
    choices.push({
      title: connection.displayName ?? connection.id,
      label: connection.displayName ?? connection.id,
      value: connection.id,
    });
  });
  return choices;
}
