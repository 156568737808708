import { Button } from "@avenueops/web-components";
import { toastError } from "utils/toast";
import { trpc2 } from "utils/trpc";

export function FeedHeader() {
  const utils2 = trpc2.useContext();

  const { mutateAsync, isLoading } = trpc2.v2.inbox.updateNotificationsReadStatus.useMutation({
    onSuccess: () => {
      utils2.v2.inbox.countInboxNotifications.invalidate();
      utils2.v2.inbox.listInboxNotifications.invalidate();
    },
  });

  const onUpdateReadStatus = async () => {
    try {
      await mutateAsync({ read: true, notificationIds: [], all: true });
    } catch (err: any) {
      const errorStr = "Error marking notifications as read";
      toastError(err, errorStr);
    }
  };

  return (
    <div className="px-4 h-full flex flex-row justify-between items-center border-b border-gray-200">
      <div className="font-medium text-sm">All notifications</div>
      <Button type="TEXT" size="SMALL" submitting={isLoading} onClick={onUpdateReadStatus}>
        Mark all as read
      </Button>
    </div>
  );
}
