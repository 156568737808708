import { Button } from "@avenueops/web-components/";
import Link from "next/link";
import React from "react";

type Props = {
  errorCode?: string;
  errorMessage: string;
  className?: string;
  onClick?: React.MouseEventHandler | (() => void);
};

export default function ErrorBox({ errorMessage, errorCode, className = "", onClick }: Props): JSX.Element {
  return (
    <div
      className={`
      ${className}
      flex items-center justify-center w-full h-full my-auto`}
    >
      <div className="flex flex-col items-center justify-center h-64 p-8 space-y-4 text-2xl bg-white rounded border shadow-sm w-128">
        {errorCode && <div className="text-2xl font-semibold sm:text-4xl">{errorCode}</div>}
        <div className="text-base text-center text-gray-700">{errorMessage}</div>
        <Link href="/" passHref className="text-base text-gray-700">
          <Button type="SECONDARY" onClick={onClick}>
            Return Home
          </Button>
        </Link>
      </div>
    </div>
  );
}
