import Skeleton from "react-loading-skeleton";

interface Props {
  count?: number;
}

export function FeedSkeleton({ count = 1 }: Props) {
  const iterations = Array.from({ length: count }, (_, index) => index + 1);
  return (
    <div className="flex flex-col gap-3">
      {iterations.map((iteration) => (
        <NotificationCellSkeleton key={iteration} />
      ))}
    </div>
  );
}

function NotificationCellSkeleton() {
  return (
    <div className="p-2 hover:bg-gray-50">
      <div className="p-2">
        <div className="flex flex-row gap-4">
          <div className="h-6 w-6 rounded-full">
            <Skeleton />
          </div>
          <div className="flex flex-col gap-4 flex-1">
            <div className="text-xs">
              <Skeleton />
            </div>
            <div className="text-gray-600 text-xs w-[50%]">
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
