import {
  ArchivedFilter,
  AssigneeOption,
  ClientUser,
  GroupBy,
  ProcessFilterZod,
  ProcessFilterZodArray,
  ProcessTableColumnZod,
  ProcessTableColumnZodArray,
  ProcessTableSortZodArray,
} from "@avenueops/shared-types";
import { ProcessTimeFrame, ProcessView } from "@prisma/client";
import { filterHasError } from "components/ProcessTable/ProcessTableFilters/utils";
import { getInitialTableColumns } from "components/ProcessTable/utils/ProcessTableColumnsUtils";
import { useUser } from "context/UserContext";
import deepEqual from "fast-deep-equal";
import _ from "lodash";
import React, { PropsWithChildren, useCallback, useState } from "react";

/**
 * All relevant fields to the process table state
 */
export interface ProcessTableStateType {
  filters?: ProcessFilterZodArray;
  search?: string;
  groupBy?: GroupBy;
  sort: ProcessTableSortZodArray;
  quickFilter?: ProcessFilterZod;
  defaultQuickFilters?: ProcessFilterZodArray;
  timeFrame: ProcessTimeFrame;
  timeFrameStartIncl?: Date; // only if timeFrame === CUSTOM
  timeFrameEndExcl?: Date; // only if timeFrame === CUSTOM
  columns: ProcessTableColumnZodArray;
}

const initialProcessTableState: ProcessTableStateType = {
  search: undefined,
  filters: [],
  groupBy: undefined,
  sort: [],
  quickFilter: undefined,
  defaultQuickFilters: undefined,
  timeFrame: "ALL_TIME",
  columns: getInitialTableColumns(),
};

export const dashboardLocalStorageKey = "DASHBOARD_STATE";
export const signalsListLocalStorageKey = "SIGNALS_LIST_STATE";

/**
 * Starter context for the process table state
 */
const ProcessTableContext = React.createContext<[ProcessTableStateType, (_: ProcessTableStateType) => void]>([
  initialProcessTableState,
  (_: ProcessTableStateType) => undefined,
]);

/**
 * Get process table state from local storage
 */
function getProcessTableStateFromSessionStorage(localStorageKey?: string): undefined | ProcessTableStateType {
  if (!localStorageKey) {
    return undefined;
  }

  const rawState = sessionStorage.getItem(localStorageKey);

  if (!rawState) {
    return undefined;
  }

  const state: ProcessTableStateType = JSON.parse(rawState);

  return state;
}

/**
 * Set process table state to local storage
 */
function setProcessTableStateToSessionStorage(localStorageKey: string, state: ProcessTableStateType) {
  sessionStorage.setItem(localStorageKey, JSON.stringify(state));
}

interface Props {
  archived: ArchivedFilter;
  signalView?: ProcessView;
  localStorageKey?: string;
  starterFilters?: ProcessFilterZod[];
}

/**
 * Provider that sets initial state. All child processes that use the state are wrapped in.
 */
export function ProcessTableProvider(props: PropsWithChildren<Props>) {
  const { archived, signalView, localStorageKey, starterFilters, children } = props;

  const user = useUser();

  const starterState: ProcessTableStateType = signalView
    ? {
        filters: (signalView.filters as ProcessFilterZod[]) ?? [],
        groupBy: (signalView.groupBy as GroupBy) ?? undefined,
        sort: (signalView.sort as ProcessTableSortZodArray) ?? [],
        timeFrame: signalView.timeFrame,
        timeFrameStartIncl: signalView.timeFrameStartIncl ?? undefined,
        timeFrameEndExcl: signalView.timeFrameEndExcl ?? undefined,
        columns: (signalView.columns as ProcessTableColumnZod[]) ?? [],
      }
    : starterFilters && starterFilters.length > 0
    ? { ...initialProcessTableState, filters: starterFilters as ProcessFilterZod[] }
    : getProcessTableStateFromSessionStorage(localStorageKey) ?? initialProcessTableState;

  const preppedStarterState = prepStarterState({ starterState, user, dashboard: localStorageKey === dashboardLocalStorageKey });
  const [processTableState, setProcessTableState] = useState<ProcessTableStateType>(preppedStarterState);
  const wrappedSetState = useCallback(
    (newState: ProcessTableStateType) => {
      if (!signalView && localStorageKey) {
        setProcessTableStateToSessionStorage(localStorageKey, newState);
      }
      setProcessTableState(newState);
    },
    [localStorageKey, signalView]
  );

  return (
    <ProcessTableContext.Provider value={[processTableState, wrappedSetState]}>
      {/* Nest the ProcessesForTableProvider in this provider for semantic separation */}
      {children}
    </ProcessTableContext.Provider>
  );
}

interface prepStarterStateProps {
  starterState: ProcessTableStateType;
  user?: ClientUser;
  dashboard?: boolean;
}

/**
 * Prep the time frame / start and end dates / assignee in the starter state
 */
export function prepStarterState({ starterState, user, dashboard }: prepStarterStateProps) {
  const updatedStarterState = { ...starterState };
  // Keep this just in case we have some old views saved that don't have time frames
  if (!starterState.timeFrame) {
    updatedStarterState.timeFrame = "PAST_WEEK" as ProcessTimeFrame;
  }

  if (starterState.timeFrameStartIncl) {
    updatedStarterState.timeFrameStartIncl = new Date(starterState.timeFrameStartIncl);
  }

  if (starterState.timeFrameEndExcl) {
    updatedStarterState.timeFrameEndExcl = new Date(starterState.timeFrameEndExcl);
  }
  if (dashboard && user) {
    if (!starterState.filters || starterState.filters?.length < 1) {
      updatedStarterState.filters = [
        {
          type: "ASSIGNEE",
          value: [{ value: { assigneeType: "USER", assigneeId: user.id }, label: "you" } as AssigneeOption],
        },
      ];
    }
  }

  return updatedStarterState;
}

/**
 * Use parent state
 */
export function useProcessTableState(): [ProcessTableStateType, (_: ProcessTableStateType) => void] {
  const context = React.useContext(ProcessTableContext);
  if (context === undefined) {
    throw new Error("useProcessTableState must be used within a ProcessTableProvider");
  }

  return context;
}

/**
 * Use group by
 */
export function useProcessTableGroupBy(): [GroupBy | undefined, (_: GroupBy) => void, () => void] {
  const [processTableState, setProcessTableState] = useProcessTableState();
  const { groupBy, filters } = processTableState;
  const setGroupBy = useCallback(
    (newGroupBy: GroupBy) => {
      let newFilters = filters;
      setProcessTableState({ ...processTableState, ...{ filters: newFilters, groupBy: newGroupBy } });
    },
    [filters, processTableState, setProcessTableState]
  );

  const clearGroupBy = useCallback(() => {
    let newFilters = filters;
    setProcessTableState({ ...processTableState, ...{ filters: newFilters, groupBy: undefined } });
  }, [filters, processTableState, setProcessTableState]);

  return [groupBy, setGroupBy, clearGroupBy];
}

/**
 * Use sort
 */
export function useProcessTableSort(): [ProcessTableSortZodArray, (_: ProcessTableSortZodArray) => void] {
  const [processTableState, setProcessTableState] = useProcessTableState();
  const { sort } = processTableState;
  const setSort = useCallback(
    (newSort: ProcessTableSortZodArray) => {
      setProcessTableState({ ...processTableState, ...{ sort: newSort } });
    },
    [processTableState, setProcessTableState],
  );

  return [sort, setSort];
}

/**
 * Use dynamic columns
 */
export function useProcessTableColumns(): [ProcessTableColumnZodArray, (_: ProcessTableColumnZodArray) => void] {
  const [processTableState, setProcessTableState] = useProcessTableState();
  const { columns } = processTableState;

  const setColumns = useCallback(
    (newColumns: ProcessTableColumnZodArray) => {
      setProcessTableState({ ...processTableState, ...{ columns: newColumns } });
    },
    [processTableState, setProcessTableState]
  );
  return [columns, setColumns];
}

/**
 * Use search
 */
export function useProcessTableSearch(): [string | undefined, (_: string) => void] {
  const [processTableState, setProcessTableState] = useProcessTableState();
  const { search } = processTableState;
  const setSearch = useCallback(
    (newSearch: string) => {
      setProcessTableState({ ...processTableState, ...{ search: newSearch } });
    },
    [processTableState, setProcessTableState]
  );

  return [search, setSearch];
}

/**
 * Use filters
 */
export function useProcessTableFilters(): [ProcessFilterZodArray | undefined, (_: ProcessFilterZodArray) => void] {
  const [processTableState, setProcessTableState] = useProcessTableState();
  const { filters } = processTableState;
  const setFilters = useCallback(
    (newFilters: ProcessFilterZodArray) => {
      setProcessTableState({ ...processTableState, ...{ filters: newFilters } });
    },
    [processTableState, setProcessTableState]
  );

  return [filters, setFilters];
}

/**
 * Use quick filters
 */
export function useProcessTableQuickFilters(): {
  defaultQuickFilters: ProcessFilterZodArray | undefined;
  setDefaultQuickFilters: (_: ProcessFilterZodArray) => void;
  clearDefaultQuickFilters: () => void;
  quickFilter: ProcessFilterZod | undefined;
  setQuickFilter: (_: ProcessFilterZod) => void;
  clearQuickFilter: () => void;
} {
  const [processTableState, setProcessTableState] = useProcessTableState();
  const { quickFilter, filters, defaultQuickFilters } = processTableState;

  const setDefaultQuickFilters = useCallback(
    (newDefaultQuickFilters: ProcessFilterZodArray) => {
      if (newDefaultQuickFilters.map((currFilter: ProcessFilterZod) => !filterHasError(currFilter)).reduce((prev, curr) => prev && curr, true)) {
        let newFilters = filters;
        if (defaultQuickFilters) {
          newFilters = removeFilterArrayFromFilters(newFilters, defaultQuickFilters);
        }
        newFilters ? newFilters.push(...newDefaultQuickFilters) : newDefaultQuickFilters;
        setProcessTableState({ ...processTableState, ...{ filters: newFilters, defaultQuickFilters: newDefaultQuickFilters } });
      }
    },
    [defaultQuickFilters, filters, processTableState, setProcessTableState]
  );

  const setQuickFilter = useCallback(
    (newQuickFilter: ProcessFilterZod) => {
      if (!filterHasError(newQuickFilter)) {
        let newFilters = filters;
        // If a quickFilter is already selected, remove it from the filters
        if (quickFilter) {
          newFilters = removeFromFilters(newFilters, quickFilter);
        }
        newFilters ? newFilters.push(newQuickFilter) : [newQuickFilter];
        setProcessTableState({ ...processTableState, ...{ filters: newFilters, quickFilter: newQuickFilter } });
      }
    },
    [filters, processTableState, quickFilter, setProcessTableState]
  );

  const clearQuickFilter = useCallback(() => {
    const newFilters = removeFromFilters(filters, quickFilter as ProcessFilterZod);
    setProcessTableState({ ...processTableState, ...{ filters: newFilters, quickFilter: undefined } });
  }, [filters, processTableState, quickFilter, setProcessTableState]);

  const clearDefaultQuickFilters = useCallback(() => {
    const newFilters = removeFilterArrayFromFilters(filters, defaultQuickFilters as ProcessFilterZodArray);
    setProcessTableState({ ...processTableState, ...{ filters: newFilters, defaultQuickFilters: undefined } });
  }, [defaultQuickFilters, filters, processTableState, setProcessTableState]);

  return {
    quickFilter,
    setQuickFilter,
    clearQuickFilter,
    defaultQuickFilters,
    setDefaultQuickFilters,
    clearDefaultQuickFilters,
  };
}

/**
 * Use time frame
 */
export function useProcessTableTimeFrame(): [TimeFrameArgs, (_: TimeFrameArgs) => void] {
  const [processTableState, setProcessTableState] = useProcessTableState();
  const { timeFrame, timeFrameStartIncl, timeFrameEndExcl } = processTableState;
  const setTimeFrame = useCallback(
    (timeFrameArgs: TimeFrameArgs) => {
      setProcessTableState({ ...processTableState, ...timeFrameArgs });
    },
    [processTableState, setProcessTableState]
  );

  return [{ timeFrame, timeFrameStartIncl, timeFrameEndExcl }, setTimeFrame];
}

/**
 * Bundled arguments for time frames
 */
export type TimeFrameArgs = { timeFrame: ProcessTimeFrame; timeFrameStartIncl: Date | undefined; timeFrameEndExcl: Date | undefined };

/**
 * Clears local storage state for context switches like logging out or switching organizations
 */
export async function clearTableStateFromLocalStorage(localStorageKey: string) {
  await localStorage.removeItem(localStorageKey);
}

function removeFromFilters(filters: ProcessFilterZod[] | undefined, filterToRemove: ProcessFilterZod) {
  if (!filters || !filterToRemove) {
    return [];
  }
  return filters.filter((f) => !(_.isEqual(f.type, filterToRemove.type) && _.isEqual(f.value, filterToRemove.value)));
}

function removeFilterArrayFromFilters(filters: ProcessFilterZod[] | undefined, filterArrayToRemove: ProcessFilterZodArray) {
  if (!filters || !filterArrayToRemove) {
    return [];
  }
  let newFilters = filters;
  filterArrayToRemove.map((filterToRemove: ProcessFilterZod) => {
    newFilters = newFilters.filter((f) => !deepEqual(f, filterToRemove));
  });
  return newFilters;
}
