import { argumentChoices } from "./argumentChoices";
import { callbacks } from "./callbacks";
import { hooks } from "./hooks";
import { metadata } from "./metadata";

export const commandBar = {
  callbacks,
  argumentChoices,
  metadata,
  hooks,
} as const;
