import * as Sentry from "@sentry/nextjs";
import AuthenticatedLayout from "components/AuthenticatedLayout";
import ErrorBox from "components/ErrorBox";
import { useOrganization } from "context/OrganizationContext";
import { useUser } from "context/UserContext";
import React, { PropsWithChildren, useEffect } from "react";

interface Props {
  resetError: () => void;
}

function FallbackComponent({ resetError }: Props): JSX.Element {
  return (
    <AuthenticatedLayout title="Oops!">
      <ErrorBox
        errorMessage="Looks like we are having some issues. Either refresh the page or click the button below to return home."
        onClick={(evt) => {
          evt.stopPropagation();
          resetError();
          evt.target.dispatchEvent(evt.nativeEvent);
        }}
      />
    </AuthenticatedLayout>
  );
}

export default function SentryBoundary({ children }: PropsWithChildren<{}>): JSX.Element {
  const user = useUser();
  const organization = useOrganization();

  useEffect(() => {
    Sentry.withScope((scope) => {
      if (user) {
        scope.setUser({
          id: user.id,
          username: user.name,
          org: organization?.name,
        });
      } else {
        scope.setUser(null);
      }
    });
  }, [user, organization]);

  return (
    <Sentry.ErrorBoundary
      fallback={({ resetError }) => <FallbackComponent resetError={resetError} />}
      beforeCapture={(scope) => {
        scope.setTag("from", "error_boundary");
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
