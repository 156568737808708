export const BASE_API_URL = process.env.NEXT_PUBLIC_BASE_API_URL || "http://localhost:5000";
export const NEXT_FRONTEND_URL = process.env.NEXT_PUBLIC_FRONTEND_URL || "http://localhost:3000";
export const NEXT_PUBLIC_SLACK_CLIENT_ID = process.env.NEXT_PUBLIC_SLACK_CLIENT_ID || "1396932170836.1389530911877";
export const NEXT_PUBLIC_SLACK_REDIRECT_URI = process.env.NEXT_PUBLIC_SLACK_REDIRECT_URI || "http://localhost:5000/slack/oauth";
export const NEXT_PUBLIC_SLACK_USER_REDIRECT_URI = process.env.NEXT_PUBLIC_SLACK_USER_REDIRECT_URI || "http://localhost:3000/api/slack/oauth/user";
export const SLACK_SCOPES = [
  "app_mentions:read",
  "channels:history",
  "channels:read",
  "chat:write",
  "chat:write.public",
  "chat:write.customize",
  "commands",
  "files:read",
  "files:write",
  "groups:history",
  "groups:read",
  "im:history",
  "im:read",
  "mpim:history",
  "mpim:read",
  "reactions:read",
  "team:read",
  "users:read",
  "users:read.email",
  "workflow.steps:execute",
  "emoji:read",
  "reactions:write",
  "channels:join",
];
export const USER_SLACK_SCOPES = ["chat:write", "files:write", "users:read", "users:read.email"];
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const AVENUE_DOCS_LINK = process.env.NEXT_PUBLIC_AVENUE_DOCS_LINK || "https://docs.useavenue.com";
export const COMMANDBAR_ORG_ID = "60d313c8";
export const INTERCOM_APP_ID = "kp5tjzq6";
export const INTERCOM_ENABLED = process.env.NODE_ENV === "production";

// Stripe
export const STRIPE_PUBLISHABLE_KEY =
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ||
  "pk_test_51OHYAPFz1ww0M3Ii9EvUwgSvAoY69SKXe9SmM5MdJYMGmQpAQnKFBp8wHzcWqYX8D0EDVcHXGp1bY5BYFEv0wQYc00ZIAmjGVu";

// Error codes
export const FORBIDDEN_ERROR_CODE = 403;
export const NOT_FOUND_ERROR_CODE = 404;
export const NOT_ALLOWED_ERROR_CODE = 405;
export const TRPC_UNAUTHORIZED_ERROR_CODE = "UNAUTHORIZED";

// Error Messages
export const VERIFY_CORRECT_ORG_ERROR_MESSAGE = "Please make sure you are signed into the correct organization.";

// Google
export const GOOGLE_SERVICE_ACCOUNT_EMAIL = process.env.NEXT_PUBLIC_GOOGLE_SERVICE_ACCOUNT_EMAIL;

// NOTION OAuth Constants
export const NEXT_PUBLIC_NOTION_CLIENT_ID = process.env.NEXT_PUBLIC_NOTION_CLIENT_ID || "";
export const NEXT_PUBLIC_NOTION_REDIRECT_URI = process.env.NEXT_PUBLIC_NOTION_REDIRECT_URI || "";

// Intercom OAuth Constants
export const NEXT_PUBLIC_INTERCOM_CLIENT_ID = process.env.NEXT_PUBLIC_INTERCOM_CLIENT_ID || "";
export const NEXT_PUBLIC_INTERCOM_REDIRECT_URI = process.env.NEXT_PUBLIC_INTERCOM_REDIRECT_URI || "";

// Salesforce OAuth Constants
export const NEXT_PUBLIC_SALESFORCE_CLIENT_ID = process.env.NEXT_PUBLIC_SALESFORCE_CLIENT_ID || "";
export const NEXT_PUBLIC_SALESFORCE_REDIRECT_URI = process.env.NEXT_PUBLIC_SALESFORCE_REDIRECT_URI || "";

// HubSpot OAuth Constants
export const NEXT_PUBLIC_HUBSPOT_CLIENT_ID = process.env.NEXT_PUBLIC_HUBSPOT_CLIENT_ID || "";
export const NEXT_PUBLIC_HUBSPOT_REDIRECT_URI = process.env.NEXT_PUBLIC_HUBSPOT_REDIRECT_URI || "";
export const HUBSPOT_SCOPES = ["crm.objects.contacts.read", "crm.objects.contacts.write"];
