import { ClientWorkflow, DescribeMonitorRadioGroupValues } from "@avenueops/shared-types";
import { IntegrationType, WorkflowTriggerType, Prisma } from "@prisma/client";
import _ from "lodash";

export function getDefaultTitleFormat(emoji: string, title: string) {
  return `${title ?? ""}`;
}

export function getTriggerType(draftId: string | undefined, monitor: Partial<ClientWorkflow & Record<string, any>>): WorkflowTriggerType {
  if (draftId) {
    let workflowTriggerType = monitor?.workflowTriggerType;
    if (workflowTriggerType) {
      return workflowTriggerType;
    }
    if (!monitor?._workflowType) {
      return WorkflowTriggerType.RAW_SQL;
    }
    if (monitor?._workflowType === DescribeMonitorRadioGroupValues.WEBHOOK || monitor?._workflowType === DescribeMonitorRadioGroupValues.FORM) {
      return WorkflowTriggerType.WEBHOOK;
    }
    if (monitor.dataViewId) {
      return WorkflowTriggerType.DATA_VIEW;
    }
    if (monitor.integrationWebhookTrigger) {
      return WorkflowTriggerType.INTEGRATION;
    }
    return WorkflowTriggerType.RAW_SQL;
  }
  return monitor?.triggerType ?? WorkflowTriggerType.RAW_SQL;
}

export function getIntegrationWebhookTrigger(draftId: string | undefined, monitor: Partial<ClientWorkflow & Record<string, any>>) {
  if (
    (draftId && // editing a draft workflow
      (monitor?._workflowType === DescribeMonitorRadioGroupValues.REACTION_ADDED || monitor?._workflowType === DescribeMonitorRadioGroupValues.NEW_MESSAGE)) ||
    (monitor?.integrationWebhookTrigger && // editing a created workflow
      (monitor?.integrationWebhookTrigger.triggerEvent === "reaction_added" || monitor?.integrationWebhookTrigger.triggerEvent === "new_message"))
  ) {
    const meta = monitor.integrationWebhookTrigger?.meta as Prisma.JsonObject;
    return {
      organizationIntegrationId: monitor?.integrationWebhookTrigger?.organizationIntegrationId ?? "",
      triggerEvent: monitor?.integrationWebhookTrigger?.triggerEvent ?? _.toLower(monitor?._workflowType),
      meta: {
        channels: (meta?.channels as string[]) ?? [],
        linkSubscriber: (meta?.linkSubscriber as boolean) ?? true,
        notificationFilters: (meta?.notificationFilters as string[]) ?? [],
      },
      type: IntegrationType.SLACK,
    };
  }
  return null;
}

export function humanizeIntegrationWebhookTriggerEvent(workflow: ClientWorkflow) {
  return _.replace(_.capitalize(workflow.integrationWebhookTrigger?.triggerEvent) ?? "Integration", "_", " ");
}
