import { getBaseMonitorValues } from "./editFormReboot/getBaseMonitorValues";
import { CreateWorkflowDraftFromModal, TimeUnit, DescribeMonitorRadioGroupValues } from "@avenueops/shared-types";
import { Button, InputOption, Modal, SmartForm } from "@avenueops/web-components";
import {
  BoltIcon,
  BookmarkIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  ClipboardDocumentListIcon,
  DocumentTextIcon,
  FaceSmileIcon,
  FolderIcon,
  InformationCircleIcon,
  PresentationChartLineIcon,
} from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { ConnectionType, DataViewType, WorkflowType } from "@prisma/client";
import EmojiPicker from "components/inputs/EmojiPicker";
import { useOrganization } from "context/OrganizationContext";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { pagesPath } from "utils/$path";
import { toastError } from "utils/toast";
import { trpc, trpc2 } from "utils/trpc";

interface Props {
  modalVisible: boolean;
  setModalVisible: (_: boolean) => void;
}
const DEFAULT_EMOJI = "🚨";
export function CreateMonitorModal({ modalVisible, setModalVisible }: Props): JSX.Element {
  const { data: connections, isLoading: isLoadingConnections } = trpc2.v2.databaseConnections.list.useQuery({});
  const organization = useOrganization();

  const defaultValues: any = {
    timeZoneOffset: new Date().getTimezoneOffset(),
    title: null,
    description: null,
    emoji: DEFAULT_EMOJI,
    _workflowType: DescribeMonitorRadioGroupValues.EVENT,
    monitorGroupId: null,
    integrationWebhookTrigger: null,
  };

  const formMethods = useForm({
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: zodResolver(CreateWorkflowDraftFromModal),
  });
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = formMethods;

  const workflowType = watch("_workflowType");

  const workflowTypeSettings: any[] = [
    {
      value: DescribeMonitorRadioGroupValues.EVENT,
      label: "Event Monitor",
      icon: <BookmarkIcon className="h-6 w-6 mr-1" strokeWidth={1.5} />,
    },
    {
      value: DescribeMonitorRadioGroupValues.REPORT,
      label: "Report Monitor",
      icon: <DocumentTextIcon className="h-6 w-6 mr-1" strokeWidth={1.5} />,
    },
    {
      value: DescribeMonitorRadioGroupValues.METRIC,
      label: "Metric Monitor",
      icon: <PresentationChartLineIcon className="h-6 w-6 mr-1" strokeWidth={1.5} />,
    },
    {
      value: DescribeMonitorRadioGroupValues.FORM,
      label: "Form",
      icon: <ClipboardDocumentListIcon className="h-6 w-6 mr-1" strokeWidth={1.5} />,
    },
    {
      value: DescribeMonitorRadioGroupValues.WEBHOOK,
      label: "Webhook",
      icon: <BoltIcon className="h-6 w-6 mr-1" strokeWidth={1.5} />,
    },
    {
      value: DescribeMonitorRadioGroupValues.REACTION_ADDED,
      label: "Reaction added in Slack",
      icon: <FaceSmileIcon className="h-6 w-6 mr-1" strokeWidth={1.5} />,
    },
    {
      value: DescribeMonitorRadioGroupValues.NEW_MESSAGE,
      label: "New message in Slack",
      icon: <ChatBubbleOvalLeftEllipsisIcon className="h-6 w-6 mr-1" strokeWidth={1.5} />,
    },
  ];

  const router = useRouter();
  const utils = trpc2.useContext();

  const { data: monitorGroups } = trpc.monitorGroups.list.useQuery();
  const getMonitorGroupChoices = () => {
    const noGroupChoice = [{ value: "", label: "No group" }];
    const monitorGroupChoices = monitorGroups
      ?.map((group: any) => {
        return { value: group.id, label: group.title };
      })
      .sort((a: InputOption<string>, b: InputOption<string>) => a.label.localeCompare(b.label));
    const availableChoices = monitorGroupChoices && monitorGroupChoices?.length > 0 ? noGroupChoice.concat(monitorGroupChoices) : noGroupChoice;
    return availableChoices;
  };

  const { mutateAsync: createDraft } = trpc2.v2.workflows.drafts.create.useMutation();

  const saveDraft = async (data: any) => {
    data.timeZoneOffset = new Date().getTimezoneOffset();
    try {
      const response = await createDraft(data);
      await utils.v2.workflows.drafts.list.invalidate();
      await router.push(pagesPath.monitors._id(response.id!).create.$url());
    } catch (error) {
      toastError(error, "There was an error when creating the monitor draft. Please try again later.");
    }
  };

  const onSubmit = (data: any) => {
    try {
      const draft = getBaseMonitorValues(data, connections, organization);
      saveDraft(draft);
      setModalVisible(false);
    } catch (error) {
      toastError(error, "Something went wrong");
    }
  };

  useEffect(() => {
    if (!modalVisible) {
      setTimeout(() => reset(defaultValues), 300);
    }
  }, [modalVisible]);

  const { mutateAsync: createMutateAsync } = trpc.monitorGroups.create.useMutation();

  const handleCreate = async (title: string) => {
    try {
      const newGroup = await createMutateAsync({ title });
      return { label: title, value: newGroup.id };
    } catch (err) {
      toast.error("There was an error creating the monitor group. Please try again later.");
    }
  };

  return (
    <Modal title="Create a new monitor" open={modalVisible} onClose={() => setModalVisible(false)}>
      <FormProvider {...formMethods}>
        <form>
          <div className="w-full flex flex-row gap-2 items-end min-w-[550px]">
            <div className="flex-1">
              <SmartForm.TextInput name="title" title="Name" placeholder="Monitor title" />
            </div>
            <div className="my-1">
              <EmojiPicker hideLabel name="emoji" control={control} />
            </div>
          </div>
          <div className="pt-6 flex items-center gap-5">
            <div className="flex-1">
              <SmartForm.DropdownSelect
                name="_workflowType"
                options={workflowTypeSettings}
                title="Trigger"
                secondaryElement={
                  <InformationCircleIcon
                    className="w-4 h-4 text-gray-400 font-bold text-base shrink-0 cursor-pointer"
                    onClick={() => window.open("https://docs.useavenue.com/en/collections/3836709-monitors", "_blank")}
                  />
                }
              />
            </div>
            {workflowType === WorkflowType.EVENT &&
            organization?.visualQueryEditorEnabled &&
            connections?.find((connection) => connection.schema && connection.connectionType === ConnectionType.POSTGRES) ? (
              <div>
                <SmartForm.ToggleGroup
                  name="dataViewType"
                  title="Editor Type"
                  type="RADIO"
                  defaultValue="SQL"
                  options={[
                    {
                      label: "SQL",
                      value: DataViewType.SQL,
                    },
                    {
                      label: "Visual Editor",
                      value: DataViewType.VISUAL,
                    },
                  ]}
                />
              </div>
            ) : null}
          </div>
          <div className="pt-6">
            <SmartForm.TextArea name="description" placeholder="Description (optional)" title="Description" />
          </div>
          <div className="pt-12 justify-between flex flex-row items-center">
            <div>
              <SmartForm.DropdownSelect
                type="BUTTON"
                name="monitorGroupId"
                placeholder="Select group"
                IndicatorIcon={FolderIcon}
                options={getMonitorGroupChoices()}
                onCreate={handleCreate}
              />
            </div>

            <Button onClick={handleSubmit(onSubmit)}>Continue</Button>
          </div>
        </form>
      </FormProvider>
    </Modal>
  );
}
