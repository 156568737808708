import { getDynamicColumns } from "./getDynamicColumns";
import { trpc, trpc2 } from "./trpc";
import { AssigneeOptionGroup, AssigneeOption, ClientAssignee } from "@avenueops/shared-types";
import { AutoAssignmentPolicyType, Role, Team, WorkflowAutoAssignmentPolicyType } from "@prisma/client";
import { useMembership } from "context/OrganizationContext";
import React from "react";

/**
 * Gets the members and teams of an org, returns them as creatable select options. Also gets the workflows default policy and transforms it into a creatable select option
 *
 * @param workflowId
 * @returns defaultPolicy for the workflow, creatable select options
 */

interface Props {
  processId?: string;
  workflowId?: string;
}

export default function useAssigneeOptions(props?: Props): {
  options: AssigneeOptionGroup[];
  isLoading: boolean;
  userAssignees?: ClientAssignee[];
  teams?: Team[];
} {
  // Get role of current user
  const membership = useMembership();
  const role = membership?.role;
  const optionsFetchEnabled = role && role !== Role.GUEST;

  // Get members of current organization and transform them into creatable select options
  const { isLoading: usersLoading, data: users } = trpc.assignees.list.useQuery(undefined, { enabled: optionsFetchEnabled });

  const defaultUserOptions = React.useMemo(() => {
    const defaultUserOptions: AssigneeOption[] | undefined = users?.map((member: ClientAssignee) => {
      return {
        label: `${member.firstName} ${member.lastName}`,
        value: {
          assigneeId: member.id,
          assigneeType: WorkflowAutoAssignmentPolicyType.USER,
        },
        iconUrl: member.image ?? "",
        metadata: {
          user: member,
        },
      };
    });

    defaultUserOptions?.unshift({
      label: "Unassign",
      value: {
        assigneeId: undefined,
        assigneeType: WorkflowAutoAssignmentPolicyType.USER,
      },
    });

    return defaultUserOptions;
  }, [users]);

  const { isLoading: assigneesLoading, data: assigneeOptions } = trpc.assignees.listFromSignal.useQuery(
    { workflowProcessId: props?.processId ?? "" },
    { enabled: optionsFetchEnabled && !!props?.processId }
  );
  const escalationGroupUserOptions: AssigneeOption[] = React.useMemo(
    () => assigneeOptions?.currentEscalationGroupAssignees?.map(assigneeToOption) ?? [],
    [assigneeOptions]
  );
  const otherEscalationGroupUserOptions: AssigneeOption[] = React.useMemo(() => {
    const otherEscalationGroupUserOptions = assigneeOptions?.otherAssignees?.map(assigneeToOption);
    if (otherEscalationGroupUserOptions) {
      otherEscalationGroupUserOptions.unshift({
        label: "Unassign",
        value: {
          assigneeId: undefined,
          assigneeType: WorkflowAutoAssignmentPolicyType.USER,
        },
      });
    }
    return otherEscalationGroupUserOptions ?? [];
  }, [assigneeOptions]);

  const { data: teams, isLoading: teamsLoading } = trpc.teams.list.useQuery(undefined, { enabled: optionsFetchEnabled });

  const { data: workflow, isLoading: workflowLoading } = trpc2.v2.workflows.get.useQuery(
    { workflowId: props?.workflowId! },
    { enabled: optionsFetchEnabled && !!props?.workflowId }
  );

  const teamOptions = React.useMemo(
    () =>
      teams
        ?.filter((team) => team.autoAssignmentPolicyType !== AutoAssignmentPolicyType.NONE)
        ?.map((team: Team) => ({
          label: team.name,
          value: {
            teamId: team.id,
            assigneeType: WorkflowAutoAssignmentPolicyType.TEAM,
          },
          metadata: {
            team,
          },
          emoji: team.emoji,
        })) ?? [],
    [teams]
  );

  const dynamicOptions = React.useMemo(
    () =>
      getDynamicColumns(workflow)?.map((column: string) => ({
        label: column,
        value: {
          assigneeTemplate: `{{${column}}}`,
          assigneeType: WorkflowAutoAssignmentPolicyType.TEMPLATE,
        },
        emoji: "🪄",
      })) ?? [],
    [workflow]
  );

  const userOptions =
    escalationGroupUserOptions && escalationGroupUserOptions.length > 0 && otherEscalationGroupUserOptions && otherEscalationGroupUserOptions.length > 0
      ? [
          { label: "Current Escalation Group", options: escalationGroupUserOptions },
          { label: "Other Users", options: otherEscalationGroupUserOptions },
        ]
      : defaultUserOptions
      ? [
          {
            label: "Users",
            options: defaultUserOptions,
          },
        ]
      : [];

  return {
    options: [
      ...(teamOptions
        ? [
            {
              label: "Teams",
              options: teamOptions,
            },
          ]
        : []),
      ...userOptions,
      ...(dynamicOptions ? [{ label: "Dynamic", options: dynamicOptions }] : []),
    ],
    isLoading: !!optionsFetchEnabled && (usersLoading || teamsLoading),
    userAssignees: users ?? [],
    teams,
  };
}

const assigneeToOption = (assignee: ClientAssignee): AssigneeOption => {
  return {
    label: `${assignee.firstName} ${assignee.lastName}`,
    value: {
      assigneeId: assignee.id,
      assigneeType: WorkflowAutoAssignmentPolicyType.USER,
    },
    iconUrl: assignee.image ?? undefined,
  };
};
