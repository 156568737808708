import classnames from "classnames";
import React from "react";

interface Props {
  id?: string;
  className?: string;
  nopadding?: boolean;
  noOverflow?: boolean;
  children: React.ReactNode;
}

export default function Card({ id, className, children, nopadding, noOverflow }: Props): JSX.Element {
  return (
    <div
      id={id ?? undefined}
      className={`${nopadding ? "" : "px-5 py-4 sm:p-6"} ${noOverflow ? "overflow-hidden" : ""} bg-white rounded
       border border-cool-gray-300 ${className}`}
    >
      {children}
    </div>
  );
}
