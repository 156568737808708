import { Button } from "@avenueops/web-components/";
import React from "react";

interface Props {
  isSignUp: boolean;
  inviteId?: string;
}

export default function SignInWithMicrosoftButton({ isSignUp, inviteId }: Props): JSX.Element {
  return (
    <a className="w-full sm:py-3 sm:text-lg" href={inviteId ? `/api/auth/microsoft?invite=${inviteId}` : "/api/auth/microsoft"}>
      <Button type="SECONDARY" full>
        <svg className="inline w-5 h-5 fill-current mr-2" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <title>Microsoft</title>
          <path d="M0 0v11.408h11.408V0zm12.594 0v11.408H24V0zM0 12.594V24h11.408V12.594zm12.594 0V24H24V12.594z" />
        </svg>
        {`${isSignUp ? "Sign up" : "Sign in"} with Microsoft`}
      </Button>
    </a>
  );
}
