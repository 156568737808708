import { ClientInboxNotification } from "@avenueops/shared-types";
import { Ellipse, DateFormat, Avatar } from "@avenueops/web-components";
import { useUser } from "context/UserContext";
import Link from "next/link";
import { trpc2 } from "utils/trpc";

interface Props {
  notification: ClientInboxNotification;
}

const AvenueIcon = require("../../../public/avenue_icon_black.svg");

export function NotificationCell({ notification }: Props) {
  const user = useUser();
  const utils2 = trpc2.useContext();

  const { mutateAsync, isLoading } = trpc2.v2.inbox.updateNotificationsReadStatus.useMutation({
    onSuccess: () => {
      utils2.v2.inbox.countInboxNotifications.invalidate();
      utils2.v2.inbox.listInboxNotifications.invalidate();
    },
  });

  const onUpdateReadStatus = async () => {
    try {
      await mutateAsync({ read: true, notificationIds: [notification.id], all: false });
    } catch (err: any) {
      // We don't need to do anything here
    }
  };

  return (
    <Link href={notification.event.href}>
      <div className="p-2 hover:bg-gray-50" onClick={onUpdateReadStatus}>
        <Ellipse active={!notification.read} align="LEFT" size="LARGE">
          <div className="p-2.5">
            <div className="flex flex-row gap-2">
              <Avatar src={notification.event.actor?.image || AvenueIcon} />
              <div className="flex flex-col gap-4">
                <div className="text-xs">{notification.body}</div>
                <div className="text-gray-600 text-xs">
                  <DateFormat date={notification.createdAt} timeZone={user.timeZone ?? undefined} type="ABSOLUTE" />
                </div>
              </div>
            </div>
          </div>
        </Ellipse>
      </div>
    </Link>
  );
}
