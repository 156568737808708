import "../styles/base.css";
import { BASE_API_URL, COMMANDBAR_ORG_ID, INTERCOM_APP_ID } from "../utils/constants";
import posthog from "../utils/posthogClient";
import { trpc } from "../utils/trpc";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { QueryClient, QueryClientConfig, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { httpLink, splitLink } from "@trpc/client";
import "antd/es/tree/style/index.css";
import axios from "axios";
import { init as initCommandBar } from "commandbar";
import { PageHead } from "components/PageHead";
import SentryBoundary from "components/error-handling/SentryBoundary";
import { OrganizationProvider, useOrganization } from "context/OrganizationContext";
import { SidebarProvider } from "context/SidebarContext";
import { UserProvider, useUser } from "context/UserContext";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { useRouter } from "next/router";
import Script from "next/script";
// @ts-ignore
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import "prismjs/themes/prism.css";
import { useEffect } from "react";
import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IntercomProvider } from "react-use-intercom";
import superjson from "superjson";
import * as constants from "utils/constants";
import { isForbiddenError } from "utils/errors";

const NEXT_PUBLIC_POSTHOG_TOKEN = process.env.NEXT_PUBLIC_POSTHOG_TOKEN;

const stripePromise = loadStripe(constants.STRIPE_PUBLISHABLE_KEY);

interface Props {
  pageProps: any;
  Component: any;
}

// Set up Axios
axios.defaults.withCredentials = true;
axios.defaults.baseURL = BASE_API_URL;
axios.defaults.timeout = 300000;

const reactQueryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      retry: (failureCount, error: any) => {
        // Don't retry forbidden/unauthorized errors
        if (isForbiddenError(error)) {
          return false;
        }
        return failureCount < 5;
      },
      retryDelay: 1000,
    },
  },
};

export default function App({ Component, pageProps }: Props): JSX.Element {
  const queryClient = React.useMemo(() => new QueryClient(reactQueryClientConfig), []);
  const trpcClient = React.useMemo(
    () =>
      trpc.createClient({
        transformer: superjson,
        links: [
          splitLink({
            condition(op) {
              return op.path.startsWith("v2.");
            },
            // when condition is true, use v2
            true: httpLink({
              url: `${BASE_API_URL}/trpc/v2`,
            }),
            // when condition is false, use batching
            false: httpLink({
              url: `${BASE_API_URL}/trpc`,
            }),
          }),
        ],
      }),
    [],
  );

  const router = useRouter();
  React.useEffect(() => {
    initCommandBar(COMMANDBAR_ORG_ID);
  }, [router.push]);

  React.useEffect(() => {
    //Binding events.
    NProgress.configure({ showSpinner: false });
    router.events.on("routeChangeStart", () => NProgress.start());
    router.events.on("routeChangeComplete", () => NProgress.done());
    router.events.on("routeChangeError", () => NProgress.done());
  }, [router]);

  React.useEffect(() => {
    TimeAgo.addDefaultLocale(en);
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <UserProvider>
              <OrganizationProvider>
                <SidebarProvider>
                  <PageHead />
                  <PostHogComponent />
                  <SentryBoundary>
                    <Component {...pageProps} />
                  </SentryBoundary>
                  <ToastContainer theme="colored" transition={Slide} position="top-right" pauseOnFocusLoss={false} pauseOnHover={false} />
                </SidebarProvider>
              </OrganizationProvider>
            </UserProvider>
          </IntercomProvider>
          {/*
            By default, disabling ReactQueryDevtools because it was causing major performance issues on the signals page.
            If you need to use them, uncomment the line below.  
          */}
          {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
      </trpc.Provider>
    </Elements>
  );
}

/**
 * A component that handles all of the posthog related logic
 */
export function PostHogComponent(): JSX.Element | null {
  const user = useUser();
  const organization = useOrganization();
  const router = useRouter();

  useEffect(() => {
    if (!NEXT_PUBLIC_POSTHOG_TOKEN) return;

    const handleRouteChange = () => {
      if (typeof window !== "undefined") {
        posthog.capture("$pageview");
      }
    };

    // first load
    handleRouteChange();

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // update the identity every time the user or organization changes
  useEffect(() => {
    if (!NEXT_PUBLIC_POSTHOG_TOKEN) return;

    posthog.identify(user?.id, { email: user?.email, name: user?.name, userId: user?.id, orgId: organization?.id, orgName: organization?.name });
  }, [user, organization]);

  return null;
}
