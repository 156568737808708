import { FORBIDDEN_ERROR_CODE, NOT_FOUND_ERROR_CODE, VERIFY_CORRECT_ORG_ERROR_MESSAGE } from "./constants";
import { TRPC_UNAUTHORIZED_ERROR_CODE } from "utils/constants";

export function handleAxiosError(e: any, resource: string, defaultErr: string) {
  if (!e) {
    return;
  }

  switch (e?.response?.status) {
    case NOT_FOUND_ERROR_CODE:
      throw new Error(`${resource} not found. ${VERIFY_CORRECT_ORG_ERROR_MESSAGE}`);
    default:
      throw new Error(defaultErr);
  }
}

export function isForbiddenError(error: any) {
  // tRPC error
  if (error?.shape?.data?.code === TRPC_UNAUTHORIZED_ERROR_CODE) {
    return true;
  }

  // Axios error
  return error.response && error.response.status === 403;
}

export function sanitizeError(error: any, fallbackMessage: string): string {
  if (error?.data?.friendlyMessage) {
    return error.data.friendlyMessage;
  }

  if (error?.response?.status == FORBIDDEN_ERROR_CODE || error.data?.code === TRPC_UNAUTHORIZED_ERROR_CODE) {
    return "You are not authorized to perform this action. Please reach out to an Admin to adjust your permissions.";
  }

  try {
    const parsedError = error?.message ? JSON.parse(error.message) : undefined;
    if (parsedError?.length >= 1 && parsedError[0]?.code === "invalid_type") {
      return `Invalid request type. ${parsedError[0].message}`;
    }
  } catch (e) {
    // Error is thrown if the error message is not JSON. We don't need to do anything here.
  }

  return fallbackMessage;
}
