import { SidebarButtonData } from "./types";
import React from "react";

interface Props {
  item: SidebarButtonData;
}

export default function SidebarButton({ item }: Props) {
  return (
    <button
      key={item.title}
      onClick={item.onClick}
      className="
            py-2
            pl-2
            flex flex-row justify-between
            text-base font-medium
            transition duration-150 ease-in-out
            text-gray-600 border-transparent
            hover:text-gray-800
            focus:outline-none focus:text-gray-800
            "
      type="button"
    >
      <div className="flex flex-row items-center space-x-2">
        <item.icon className="inline h-5 w-5" /> <span>{item.title}</span>
      </div>
    </button>
  );
}
