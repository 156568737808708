import { argumentChoices } from "../argumentChoices";
import { metadata } from "../metadata";
import { useEffect } from "react";
import { trpc2 } from "utils/trpc";
import useAssigneeOptions from "utils/useAssigneeOptions";

function useProcessEffect({ processId, workflowId, condition }: { processId: string; workflowId: string; condition?: boolean }) {
  const { options } = useAssigneeOptions();
  const { data: statuses } = trpc2.v2.dynamicStatus.list.useQuery();

  useEffect(() => {
    if (condition !== undefined && !condition) return;

    metadata.processes.setProcessInFocus(processId);
    argumentChoices.processes.setStatusArgs(statuses ?? []);
    argumentChoices.processes.setAssigneeArgs(options);
    metadata.processes.setCanEscalate(true);

    return () => {
      metadata.processes.setProcessInFocus(undefined);
    };
  }, [processId, workflowId, condition, statuses, options]);
}

function useBulkProcessesEffect({ processIds }: { processIds: string[] }) {
  const { options } = useAssigneeOptions();

  useEffect(() => {
    // If there's a process in focus, don't update the commandbar
    const processInFocus = window.CommandBar?.shareContext()?.processInFocus;
    if (processInFocus) return;

    argumentChoices.processes.setStatusArgs("STATIC");
    argumentChoices.processes.setAssigneeArgs(options);

    if (processIds.length > 0) {
      metadata.processes.setProcesses(processIds);
    } else {
      metadata.processes.setProcesses(undefined);
    }

    // For now we only support escalating one process at a time
    if (processIds.length === 1) {
      metadata.processes.setCanEscalate(true);
    } else {
      metadata.processes.setCanEscalate(false);
    }
  }, [processIds, options]);
}

export const processes = {
  useProcessEffect,
  useBulkProcessesEffect,
} as const;
