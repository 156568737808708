import { AssignUserInput, BulkActionSelection } from "@avenueops/shared-types";
import { ProcessStatus } from "@prisma/client";
import { CallbackMap } from "commandbar/build/internal/src/client/CommandBarClientSDK";
import {
  useAssignProcessMutation,
  useBulkAssignProcessMutation,
  useBulkSetStatusMutation,
  useEscalateProcessMutation,
  useSetStatusMutation,
} from "utils/state/processes";
import { noOp } from "utils/state/utils";

function useCallbacks(): CallbackMap {
  const { mutateAsync: setStatus } = useSetStatusMutation();
  const { mutateAsync: bulkSetStatus } = useBulkSetStatusMutation();
  const { mutateAsync: escalateSignal } = useEscalateProcessMutation();
  const { mutateAsync: assignSignal } = useAssignProcessMutation();
  const { mutateAsync: bulkAssignSignals } = useBulkAssignProcessMutation();

  // TODO: Only enable these callbacks based on certain meta

  // Catch errors below b/c mutation handles onError
  return {
    updateSignalStatus: async (args: { Status: { label: string; value: string } }, ctx) => {
      const {
        Status: { value },
      } = args;

      // If process in focus, use that
      const processInFocus: string | undefined = ctx?.processInFocus;
      if (processInFocus) {
        await setStatus({ processId: processInFocus, statusId: value }).catch(noOp);
        return;
      }

      const processIds: string[] | undefined = ctx?.processIds;

      if (!processIds) return;

      // ProcessStatus is only used for bulk changes
      if (value) {
        await bulkSetStatus({
          bulkSignalsInput: {
            selection: BulkActionSelection.Enum.EXPLICIT,
            signalIds: processIds,
          },
          statusId: value,
        }).catch(noOp);
        return;
      }

      // Else we're using a dynamic status for a single change
      const processId = ctx?.processIds?.[0];
      await setStatus({ processId, statusId: value }).catch(noOp);
    },
    escalateSignal: async (_, ctx) => {
      const processId: string | undefined = ctx?.processInFocus ? ctx?.processInFocus : ctx?.processIds?.[0];
      if (!processId) return;
      await escalateSignal({ processId }).catch(noOp);
    },
    assignSignal: async (args: { Assignee: { label: string; value: AssignUserInput["assigneeInfo"] } }, ctx) => {
      // If process in focus, use that
      const processInFocus: string | undefined = ctx?.processInFocus;
      if (processInFocus) {
        await assignSignal({ processId: processInFocus, assigneeInfo: args.Assignee.value }).catch(noOp);
        return;
      }

      const processIds: string[] | undefined = ctx?.processIds;
      if (!processIds) return;

      if (processIds.length > 1) {
        await bulkAssignSignals({
          bulkSignalsInput: {
            selection: BulkActionSelection.Enum.EXPLICIT,
            signalIds: processIds,
          },
          assigneeInfo: args.Assignee.value,
        }).catch(noOp);
        return;
      }

      await assignSignal({ processId: processIds[0], assigneeInfo: args.Assignee.value }).catch(noOp);
    },
  };
}

export const processes = {
  useCallbacks,
} as const;
