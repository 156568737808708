function setProcesses(processIds: string[] | undefined) {
  window.CommandBar?.addMetadata("processIds", processIds);
}

function setProcessInFocus(processId: string | undefined) {
  window.CommandBar?.addMetadata("processInFocus", processId);
}

function setCanEscalate(value: boolean) {
  window.CommandBar?.addMetadata("canEscalate", value);
}

export const processes = {
  setProcesses,
  setProcessInFocus,
  setCanEscalate,
} as const;
