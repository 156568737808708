import type { TrpcAppRouter, SecondaryTrpcAppRouter } from "@avenueops/api";
import { createTRPCProxyClient, httpBatchLink, httpLink } from "@trpc/client";
import { createTRPCReact, TRPCClientError } from "@trpc/react-query";
import { CreateTRPCReact } from "@trpc/react-query/dist/createTRPCReact";

export const trpcProxyClient = createTRPCProxyClient<TrpcAppRouter>({
  links: [
    httpLink({
      url: "/api/trpc",
    }),
  ],
});

export const trpc: CreateTRPCReact<TrpcAppRouter, unknown, null> = createTRPCReact<TrpcAppRouter>();
export const trpc2: CreateTRPCReact<SecondaryTrpcAppRouter, unknown, null> = createTRPCReact<SecondaryTrpcAppRouter>();

export type AvenueTRPCClientError = TRPCClientError<TrpcAppRouter>;

export function isTRPCClientError(error: any) {
  return error?.name === TRPCClientError.name;
}
