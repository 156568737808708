import { DecryptedConnection, DescribeMonitorRadioGroupValues, TimeUnit } from "@avenueops/shared-types";
import {
  FilterMatchType,
  MessageFormattingType,
  MetricAggregationFunction,
  MetricComparisonOperator,
  Organization,
  WorkflowAlertingConditions,
  WorkflowTriggerType,
} from "@prisma/client";
import _ from "lodash";
import { BASE_API_URL } from "utils/constants";
import { getConnectionChoices } from "utils/getConnectionChoices";
import { getDefaultTitleFormat } from "utils/workflows";
import { v4 } from "uuid";

const DEFAULT_EMOJI = "🚨";

export function getBaseMonitorValues(data: any, connections?: DecryptedConnection[], organization?: Organization) {
  let workflowType;
  let workflowTriggerType;
  let alertingConditions;
  let triggerEvent;
  switch (data._workflowType) {
    case DescribeMonitorRadioGroupValues.WEBHOOK:
      workflowTriggerType = WorkflowTriggerType.WEBHOOK;
      alertingConditions = WorkflowAlertingConditions.PASSTHROUGH;
      workflowType = "EVENT";
      break;
    case DescribeMonitorRadioGroupValues.FORM:
      workflowTriggerType = WorkflowTriggerType.WEBHOOK;
      alertingConditions = WorkflowAlertingConditions.PASSTHROUGH;
      workflowType = "EVENT";
      break;
    case DescribeMonitorRadioGroupValues.EVENT:
      workflowTriggerType = WorkflowTriggerType.RAW_SQL;
      alertingConditions = WorkflowAlertingConditions.NEW_ROW;
      workflowType = "EVENT";
      break;
    case DescribeMonitorRadioGroupValues.REPORT:
      workflowTriggerType = WorkflowTriggerType.RAW_SQL;
      alertingConditions = WorkflowAlertingConditions.PASSTHROUGH;
      workflowType = "EVENT";
      break;
    case DescribeMonitorRadioGroupValues.REACTION_ADDED:
    case DescribeMonitorRadioGroupValues.NEW_MESSAGE:
      workflowTriggerType = WorkflowTriggerType.INTEGRATION;
      alertingConditions = WorkflowAlertingConditions.PASSTHROUGH;
      workflowType = "EVENT";
      triggerEvent = _.toLower(data._workflowType);
      break;
    case DescribeMonitorRadioGroupValues.METRIC:
      workflowTriggerType = WorkflowTriggerType.RAW_SQL;
      alertingConditions = WorkflowAlertingConditions.METRIC_THRESHOLD;
      workflowType = "METRIC";
      break;
  }

  return {
    // Describe the monitor
    title: data.title,
    description: data.description,
    emoji: data.emoji,
    monitorGroupId: data.monitorGroupId,
    tagIds: [],

    // Configure alert conditions
    workflowType: workflowType,
    alertingConditions: alertingConditions,
    workflowTriggerType: workflowTriggerType,
    timeZoneOffset: new Date().getTimezoneOffset(),

    integrationWebhookTrigger: {
      triggerEvent,
    },

    // Format alert
    titleFormat: getDefaultTitleFormat(data.emoji, data.title),
    messageFormat: null,
    messageFormattingType: MessageFormattingType.PER_ROW,
    aggregateResults: false,

    // Data source
    databaseId: connections && connections.length > 0 ? getConnectionChoices(connections)[0].value : null,
    queryMeta: {
      includeHeaders: true,
      isAggregation: false,
    },
    // Data View / SQL Query
    dataViewType: data.dataViewType,
    visualQueryBlob: null,
    dataViewId: null,
    query: null,
    idColumns: null,
    // Webhook
    webhook: `${BASE_API_URL}/trigger/${v4()}`,
    formTemplateId: null,
    formTemplateJson: null,
    // Visual Editor
    filters: [],
    data: null,
    dynamicColumns: [],
    enrichment: null,

    // Filters
    rowFilters: [],
    rowFilterMatchType: FilterMatchType.ALL,

    // Metrics alert conditions
    metricId: null,
    metricTimestampColumn: null,
    metricValueColumn: null,
    metricThreshold: null,
    metricLookbackValue: 60,
    metricLookbackUnit: TimeUnit.MINUTES,
    metricAggregationFunction: MetricAggregationFunction.SUM,
    metricComparisonOperator: MetricComparisonOperator.GREATER_THAN,
    metricPercentile: 90,
    metricRunsToHealthy: null,

    // Playbook
    priority: "P2",
    autoAssignee: null,
    shouldAutoResolve: false,

    // Meta fields (not for submission, controls composite fields)
    _workflowType: data._workflowType ?? "EVENT", // TODO: Update this field to replace current workflowType property (https://linear.app/avenue/issue/AVE-3950/update-workflow-type-to-reflect-actual-workflow-type)
  };
}
