import { ProcessTableColumnZod } from "@avenueops/shared-types";
import _ from "lodash";

const presetHeaders = {
  checkbox: { title: "Checkbox", visibility: true },
  title: { title: "Title", visibility: true },
  statusLabel: { title: "Status", visibility: true },
  assigneeImage: { title: "Assignee", visibility: true },
  message: { title: "Message", visibility: false },
  created_at: { title: "Triggered At", visibility: true },
  commentsLength: { title: "Comments", visibility: true },
  tagsArray: { title: "Tags", visibility: true },
  priority: { title: "Priority", visibility: true },
  workflowTitle: { title: "Monitor Title", visibility: false },
  hasSendingErrors: { title: "Notification Error", visibility: false },
  timeElapsed: { title: "Time Elapsed", visibility: false },
} satisfies Record<string, { title: string; visibility: boolean }>;

export function getInitialTableColumns() {
  const signalMetadataColumns = Object.keys(presetHeaders) as Array<keyof typeof presetHeaders>;
  return signalMetadataColumns.map((columnId) => {
    return {
      id: `${ColumnTypes.META}${columnId}`,
      visibility: presetHeaders[columnId].visibility,
    } as ProcessTableColumnZod;
  });
}

export function getColumnNameFromId(columnId: string) {
  if (columnId.startsWith(ColumnTypes.DYNAMIC)) {
    return `{{${_.replace(columnId, ColumnTypes.DYNAMIC, "")}}}`;
  }
  const truncatedColumnId = columnId.substring(ColumnTypes.META.length);
  if (presetHeaders.hasOwnProperty(truncatedColumnId)) {
    const castedTruncatedColumnId = truncatedColumnId as keyof typeof presetHeaders;
    return presetHeaders[castedTruncatedColumnId].title;
  }
  return truncatedColumnId;
}

export function getColumnValueFromId(columnId: string) {
  return columnId.startsWith(ColumnTypes.META) ? columnId.substring(ColumnTypes.META.length) : columnId.substring(ColumnTypes.DYNAMIC.length);
}

export function isColumnDynamic(columnId: string) {
  return columnId.startsWith(ColumnTypes.DYNAMIC);
}

export function getColumnType(columnId: string) {
  return columnId.startsWith(ColumnTypes.DYNAMIC) ? ColumnTypes.DYNAMIC : ColumnTypes.META;
}

export enum ColumnTypes {
  DYNAMIC = "DYNAMIC_",
  META = "META_",
}

export function getColumnId(type: ColumnTypes, name: string) {
  return `${type}${name}`;
}

export const fixedOrderColumns = [getColumnId(ColumnTypes.META, "checkbox")];

const sortableColumns = [
  "META_title",
  "META_created_at",
  "META_statusLabel",
  "META_assigneeImage",
  "META_message",
  "META_commentsLength",
  "META_priority",
  "META_workflowTitle",
];

export function canSort(column: string) {
  return sortableColumns.includes(column);
}
