import React from "react";

type SideBarContext = {
  sidebarOpen: boolean;
  setSidebarOpen: any;
};

const SidebarContext = React.createContext<SideBarContext | undefined>(undefined);

function SidebarProvider(props: any) {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);

  return <SidebarContext.Provider value={{ sidebarOpen, setSidebarOpen }} {...props} />;
}

function useSidebarOpen() {
  const context = React.useContext(SidebarContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a SidebarProvider`);
  }
  return context.sidebarOpen;
}

function useSetSidebarOpen() {
  const context = React.useContext(SidebarContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a SidebarProvider`);
  }
  return context.setSidebarOpen;
}

export { SidebarProvider, useSetSidebarOpen, useSidebarOpen };
