import { Role } from "@prisma/client";
import OrganizationsDropdown from "../OrganizationsDropdown";
import { NotificationInbox } from "../pages/notificationInbox/NotificationInbox";
import SidebarButton from "./SidebarButton";
import { SidebarCreateMonitorButton } from "./SidebarCreateMonitorButton";
import SidebarLink from "./SidebarLink";
import { SideBarItemType } from "./types";
import { useLowerSection } from "./useLowerSection";
import useSidebarSections from "./useSidebarSections";
import { useMembership, useOrganization } from "context/OrganizationContext";
import Link from "next/link";
import React from "react";

export default function Sidebar() {
  const sidebarSections = useSidebarSections();
  const lowerSection = useLowerSection();
  const organization = useOrganization();
  const membership = useMembership();
  const role = membership?.role;

  return (
    <div className="py-2 sm:py-6 px-3 flex flex-col justify-between h-full overflow-auto">
      <div>
        <div className="hidden sm:flex mb-4 justify-between items-center">
          <Link href={organization?.dashboardEnabled ? "/dashboard" : "/monitors"}>
            <div className="flex items-center flex-shrink-0 cursor-pointer">
              <img className="hidden w-auto h-6 lg:block" src={require("../../public/avenue_logo_final_black.svg")} alt="Avenue logo" />
            </div>
          </Link>
          <div className="lg:block hidden">
            <NotificationInbox />
          </div>
        </div>
        <div className="space-y-3 pt-2 pb-3">
          {role !== Role.GUEST ? <SidebarCreateMonitorButton /> : null}
          {sidebarSections.map((section) => (
            <div key={section.sectionTitle} className="pb-1">
              <div className="pb-1 text-gray-500 text-sm font-semibold">{section.sectionTitle}</div>
              {section.items.map((item) =>
                item.type === SideBarItemType.LINK ? <SidebarLink item={item} key={item.href} /> : <SidebarButton item={item} key={item.title} />,
              )}
            </div>
          ))}
        </div>
      </div>
      <div>
        {lowerSection.map((item) =>
          item.type === SideBarItemType.LINK ? <SidebarLink item={item} key={item.href} /> : <SidebarButton item={item} key={item.title} />,
        )}
        <div className="w-full">
          <OrganizationsDropdown />
        </div>
      </div>
    </div>
  );
}
