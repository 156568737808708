import GeneralModal from "./GeneralModal";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { ComponentType, useState } from "react";

interface Props {
  backTo: { href: string; title: string; Icon?: React.FC<any> }[];
  title?: string;
  description?: string;
  Icon?: React.FC<any>;
  shouldConfirm?: boolean;
}

export default function BreadCrumb({ backTo, shouldConfirm, title, description, Icon }: Props) {
  return (
    <div className="flex flex-row gap-3 items-center flex-wrap">
      {backTo.map((page, index) => {
        const { Icon, title, href } = page;
        return (
          <div className="flex flex-row gap-3 items-center" key={index}>
            {index !== 0 ? <ChevronRightIcon className="h-4 w-4 text-gray-500" /> : null}
            <BreadCrumbItem key={index} href={href} title={title} Icon={Icon} shouldConfirm={shouldConfirm} />
          </div>
        );
      })}
      {(title || description) && backTo.length > 0 ? <ChevronRightIcon className="h-4 w-4 text-gray-950" /> : null}
      {Icon ? <Icon className="text-gray-950 h-5 w-5" /> : null}
      {title ? <span className="text-gray-950">{title}</span> : null}
      {description ? <span className="text-gray-700 text-sm hidden lg:flex">{description}</span> : null}
    </div>
  );
}

interface ItemProps {
  href: string;
  title: string;
  Icon?: React.FC<any>;
  shouldConfirm?: boolean;
}

function BreadCrumbItem({ href, title, Icon, shouldConfirm }: ItemProps) {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const router = useRouter();

  const className = "flex flex-row gap-3 items-center font-normal text-gray-700 hover:text-gray-950 cursor-pointer whitespace-nowrap";

  return (
    <>
      {shouldConfirm ? (
        <div className={className} onClick={() => setModalVisible(true)}>
          {Icon ? <Icon className="w-4 h-4" /> : null}
          {title}
        </div>
      ) : (
        <Link className={className} href={href}>
          {Icon ? <Icon className="w-4 h-4" /> : null}
          {title}
        </Link>
      )}
      <GeneralModal modalVisible={modalVisible} setModalVisible={setModalVisible} title="Are you sure?" onConfirm={() => router.push(href)}>
        By leaving this page, you will lose any unsaved changes.
      </GeneralModal>
    </>
  );
}
