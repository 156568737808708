import { FormElement, FormElements, Schema } from "@avenueops/frontend-shared";
import { ClientWorkflow } from "@avenueops/shared-types";

export function getDynamicColumns(workflow?: ClientWorkflow): string[] {
  let columns: string[] = [];
  if (!workflow) return columns;

  // If the monitor has an associated data view, we can get the columns from there.
  if (workflow.dataView) {
    columns = workflow.dataView.columns;
  }

  // Otherwise, we can get the columns from the monitor's associated form template.
  if (workflow.formTemplate) {
    const templateData = workflow.formTemplate.templateJson as unknown as Schema;
    const elementsData = templateData?.elements;

    // Make sure there are elements in the template.
    if (!elementsData) {
      return columns;
    }

    // Extract the dataIds from the elements.
    const formColumns = Object.keys(elementsData)
      .map((elementKey: string) => {
        return elementsData[elementKey];
      })
      .filter((element): element is FormElements | FormElement => {
        // Check if the element has a dataId property (i.e. is not a DisplayElement).
        return "dataId" in element && element.dataId !== undefined;
      })
      .map((element) => {
        return element.dataId as string;
      });
    columns = columns.concat(formColumns, ["username", "submitter_email"]);
  }
  return columns;
}
