import React, { useEffect, useMemo, useRef, useState } from "react";

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useIsInViewport(ref: any, threshold = 0.01, observerDeps: any[] = []) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), {
        rootMargin: "0px",
        threshold,
      }),
    [...observerDeps]
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    } else {
      setIsIntersecting(false);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export function useOutsideClick(ref: React.MutableRefObject<any>, callback: (e?: MouseEvent) => void, dependencies?: any[]) {
  useEffect(() => {
    const handleCallback = (event: MouseEvent) => {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    };

    document.addEventListener("mousedown", handleCallback);
    return () => {
      document.removeEventListener("mousedown", handleCallback);
    };
  }, [ref, ...(dependencies ?? [])]);
}

// from https://usehooks.com/useEventListener/
export function useEventListener(eventName: any, handler: (event: any) => void, element: HTMLElement | Window = window) {
  // Create a ref that stores handler
  const savedHandler = useRef(null) as unknown as Omit<React.MutableRefObject<undefined>, "current"> & { current: (event: any) => void };

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: any) => savedHandler.current(event);
      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}
