import SlackLogo from "./SidebarSlackLogo";
import { SidebarItemData, SideBarItemType } from "./types";
import { READ_OP, WRITE_OP } from "@avenueops/frontend-shared";
import { BanknotesIcon, ChatBubbleOvalLeftEllipsisIcon, Cog8ToothIcon, DocumentTextIcon, EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import { useAbilities, useBillingPlan, useOrganization } from "context/OrganizationContext";
import { useIntercom } from "react-use-intercom";
import { AVENUE_DOCS_LINK } from "utils/constants";
import { trpc2 } from "utils/trpc";

export function useLowerSection(): SidebarItemData[] {
  const abilities = useAbilities();
  const org = useOrganization();
  const canWriteOrg = abilities.can(WRITE_OP, "Organization");
  const billingPlan = useBillingPlan();
  const { show } = useIntercom();

  const isDirectorySyncEnabled = org?.directorySyncEnabled;

  return [
    {
      href: "/account/settings",
      title: "Settings",
      icon: Cog8ToothIcon,
      type: SideBarItemType.LINK,
      subItems: [
        ...(abilities.can(READ_OP, "WorkflowEvent") ? [{ href: "/account/audit-log", title: "Audit Log", type: SideBarItemType.LINK }] : []),
        ...(isDirectorySyncEnabled ? [{ href: "/account/identity-providers", title: "Identity Providers", type: SideBarItemType.LINK }] : []),
      ],
    },
    // ...(billingPlan && abilities.can(READ_OP, "BillingPlan") ? [{ href: "/billing", title: "Billing", icon: BanknotesIcon, type: SideBarItemType.LINK }] : []),
    { href: AVENUE_DOCS_LINK, title: "Documentation", icon: DocumentTextIcon, type: SideBarItemType.LINK },
    { onClick: () => show(), title: "Get Help", icon: ChatBubbleOvalLeftEllipsisIcon, type: SideBarItemType.BUTTON },
    ...(org?.slackConnectSupportChannel
      ? [{ href: org?.slackConnectSupportChannel, title: "Chat with us on Slack", icon: SlackLogo, type: SideBarItemType.LINK }]
      : []),
  ] as SidebarItemData[];
}
