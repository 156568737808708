import Head from "next/head";

interface Props {
  title?: string;
}

export function PageHead({ title }: Props): JSX.Element {
  const prefix = process.env.NODE_ENV === "development" ? "Avenue - Local" : "Avenue";
  const titleString = title ? `${prefix} - ${title}` : prefix;

  return (
    <Head>
      <title>{titleString}</title>
      <link rel="shortcut icon" href="/avenue_icon_black.svg" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest"></link>
    </Head>
  );
}
