import useLocalStorageState from "../../utils/useLocalStorageState";
import ActiveLink from "../generic-building-blocks/ActiveLink";
import { BetaTag } from "../generic-building-blocks/BetaTag";
import SidebarButton from "./SidebarButton";
import { SideBarItemType, SidebarLinkData, SubSidebarItemData } from "./types";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useSetSidebarOpen } from "context/SidebarContext";
import { NextRouter, useRouter } from "next/router";
import React, { useEffect } from "react";

interface Props {
  item: SidebarLinkData;
}

export default function SidebarLink({ item }: Props) {
  const router = useRouter();

  const [isExpanded, setIsExpanded] = useLocalStorageState<boolean>(item.href, true);
  const isSelected = (hrefSelected(router, item.href) || (!isExpanded && subItemSelected(router, item.subItems))) ?? false;

  return (
    <>
      <ActiveLink key={item.href} href={item.href} className="flex flex-row justify-between px-2 group" itemSelected={isSelected}>
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-row items-center space-x-2">
            <item.icon className="inline h-5 w-5" /> <span>{item.title}</span>
            {item.betaTag && <BetaTag />}
            {item.titleBadge !== undefined ? (
              <div
                className={`flex flex-row items-center justify-end text-sm h-5 px-[6px] rounded-md border ${
                  isSelected ? "border-primary-main" : "group-hover:border-primary-main focus:border-primary-main border-gray-600"
                }`}
              >
                {item.titleBadge}
              </div>
            ) : null}
          </div>
        </div>
        {item.subItems && item.subItems?.length > 0 ? (
          isExpanded ? (
            <ChevronDownIcon
              className="inline h-4 w-4 hover:text-black"
              onClick={(event) => {
                (document.activeElement as any).blur();
                event.preventDefault();
                setIsExpanded(false);
              }}
            />
          ) : !isExpanded ? (
            <ChevronRightIcon
              className="inline h-4 w-4 hover:text-black"
              onClick={(event) => {
                (document.activeElement as any).blur();
                event.preventDefault();
                setIsExpanded(true);
              }}
            />
          ) : null
        ) : null}
      </ActiveLink>
      {item.subItems && item.subItems.length > 0 && isExpanded ? (
        <div className="pb-2 pt-1 pl-1">
          {item.subItems?.map((subItem) => (
            <div key={subItem.title} className="ml-3 border-l-2 pl-2 border-cool-gray-300">
              {subItem.type === SideBarItemType.LINK ? (
                <ActiveLink className="py-1 pl-2" href={subItem.href} itemSelected={hrefSelected(router, subItem.href)}>
                  <span>{subItem.title}</span>
                  {item.betaTag && <BetaTag />}
                </ActiveLink>
              ) : (
                <SidebarButton item={subItem} />
              )}
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
}

/**
 * The current url matches the given href
 */
function hrefSelected(router: NextRouter, href: string) {
  return router.asPath.startsWith(href);
}

/**
 * The current url matches an href of one of the given sub items
 */
function subItemSelected(router: NextRouter, subItems?: SubSidebarItemData[]) {
  return subItems?.map((subItem) => subItem.type === SideBarItemType.LINK && hrefSelected(router, subItem.href)).reduce((prev, cur) => prev || cur, false);
}
