import Card from "./generic-building-blocks/Card";
import InputField from "./inputs/InputField";
import { Button } from "@avenueops/web-components";
import { LockClosedIcon } from "@heroicons/react/20/solid";
import { Role } from "@prisma/client";
import SignInWithGoogleButton from "components/SignInWithGoogle";
import SignInWithMicrosoftButton from "components/SignInWithMicrosoft";
import { useOrganization, useMembership } from "context/OrganizationContext";
import { useUser } from "context/UserContext";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BASE_API_URL } from "utils/constants";
import Banner from "./Banner";

interface Props {
  isSignUp: boolean;
  inviteId?: string;
  errorMsg?: string;
}

function SSOForm() {
  const [emailVisible, setEmailVisible] = React.useState(false);

  const defaultValues = {
    email: "",
  };

  const formMethods = useForm({
    defaultValues,
  });

  const onSubmit = ({ email }: { email: string }) => {
    window.location.assign(`${BASE_API_URL}/auth/email/login?email=${encodeURIComponent(email)}`);
  };

  const { register, handleSubmit } = formMethods;
  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col gap-4">
        {emailVisible ? (
          <InputField
            title="Email"
            inputType="email"
            placeholder="first.last@enterprise.com"
            className="w-full"
            register={register("email", { required: true })}
          />
        ) : null}
        <Button
          type="SECONDARY"
          submit={emailVisible}
          onClick={
            !emailVisible
              ? () => {
                  setEmailVisible(true);
                }
              : undefined
          }
        >
          <LockClosedIcon className="inline w-5 h-5 fill-current mr-2" />
          Sign in with SAML SSO
        </Button>
      </form>
    </FormProvider>
  );
}

export default function SignIn({ isSignUp, inviteId, errorMsg }: Props): JSX.Element | null {
  const user = useUser();
  const membership = useMembership();
  const router = useRouter();
  const organization = useOrganization();

  if (user) {
    if (organization?.dashboardEnabled) {
      router.push("/dashboard");
    } else if (membership?.role === Role.CREATOR || membership?.role === Role.ADMIN) {
      router.push("/monitors");
    } else {
      router.push("/signals");
    }
    return null;
  }

  return (
    <div className={`relative flex flex-col justify-around bg-cool-gray-100 h-screen`} style={{ minHeight: 720 }}>
      <div className="flex flex-col items-center min-h-full p-4 sm:p-0" style={{ minHeight: 720 }}>
        <a href="https://useavenue.com">
          <img className="block w-16 h-16 mb-6" src={require("../public/avenue_icon_black.svg")} alt="Avenue logo" />
        </a>

        <h2 className="text-2xl font-semibold leading-9 text-center text-gray-800">{isSignUp ? "Get started with Avenue" : "Sign in to your account"}</h2>
        {isSignUp ? (
          <Link href={inviteId ? `/signin?invite=${inviteId}` : "/signin"} legacyBehavior>
            <u className="mb-8 font-semibold leading-9 text-center text-gray-800 cursor-pointer">go back to sign in</u>
          </Link>
        ) : (
          <Link href={inviteId ? `/signup?invite=${inviteId}` : "/plans"} legacyBehavior>
            <u className="mb-8 font-semibold leading-9 text-center text-gray-800 cursor-pointer">or sign up</u>
          </Link>
        )}

        <div className="flex flex-col gap-2">
          {errorMsg ? <Banner type="ERROR">{errorMsg}</Banner> : null}
          <Card className={`flex flex-col gap-4 items-center justify-center w-full sm:w-128`}>
            <div className="w-full">
              <SignInWithGoogleButton isSignUp={isSignUp} inviteId={inviteId} />
            </div>
            <div className="w-full">
              <SignInWithMicrosoftButton isSignUp={isSignUp} inviteId={inviteId} />
            </div>
            <div className="w-full relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
            </div>
            <SSOForm />
          </Card>
        </div>
        <div className="mt-6 font-normal text-gray-800 text-center">
          By signing in, you agree to our
          <br />
          <a href="https://avenue.so/terms-of-service" className="font-semibold hover:underline">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href="https://avenue.so/privacy-policy" className="font-semibold hover:underline">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
}
