import { FeedSkeleton } from "./FeedSkeleton";
import { NotificationCell } from "./NotificationCell";
import { ClientInboxNotification } from "@avenueops/shared-types";
import { OvalLoader } from "@avenueops/web-components";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import InfiniteScroll from "react-infinite-scroll-component";
import { trpc2 } from "utils/trpc";

const TAKE_NUM = 50;

export function FeedItems() {
  const { hasNextPage, fetchNextPage, data, isLoading } = trpc2.v2.inbox.listInboxNotifications.useInfiniteQuery(
    {
      take: TAKE_NUM,
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.notifications.length === TAKE_NUM ? lastPage.nextCursor : undefined;
      },
    }
  );
  const notifications: ClientInboxNotification[] = data ? data.pages.flatMap((page) => page.notifications) : [];

  return isLoading ? (
    <FeedSkeleton count={5} />
  ) : notifications.length === 0 ? (
    <EmptyFeed />
  ) : (
    <div className="overflow-auto h-full" id="feed-items">
      <InfiniteScroll
        dataLength={notifications.length}
        next={fetchNextPage}
        hasMore={hasNextPage ?? false}
        scrollableTarget={"feed-items"}
        loader={
          <div className="mt-4 flex justify-center">
            <OvalLoader type="DEFAULT" size={30} />
          </div>
        }
      >
        {notifications.map((notification: ClientInboxNotification) => (
          <NotificationCell key={notification.id} notification={notification} />
        ))}
      </InfiniteScroll>
    </div>
  );
}

function EmptyFeed() {
  return (
    <div className="flex flex-col gap-2 w-full h-full justify-center items-center text-sm text-gray-900">
      <div className="p-3.5 rounded-full bg-gray-50">
        <CheckCircleIcon className="h-8 w-8 " />
      </div>
      <div className="font-semibold text-sm">No notifications</div>
      <div className="font-normal text-xs w-48 text-center">Your notifications will appear here when there's activity in your workspace.</div>
    </div>
  );
}
