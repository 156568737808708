import { Button } from "@avenueops/web-components/";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { ReactNode, useCallback } from "react";
import { Fragment } from "react";
import { toast } from "react-toastify";

interface Props {
  modalVisible: boolean;
  setModalVisible: (bool: boolean) => void;
  children?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  confirmText?: ReactNode;
  cancelText?: ReactNode;
  confirmDanger?: boolean;
  headerAction?: ReactNode;
  secondaryAction?: {
    title: ReactNode;
    action: () => void;
    danger?: boolean;
  };
  disableConfirm?: boolean;
  disableCancel?: boolean;
  noWidth?: boolean;
  noMaxWidth?: boolean;
  widthOverride?: string;
  onCancel?: Function;
  onConfirm?: Function;
  isLoading?: boolean;
  isSubmitting?: boolean;
}

export default function GeneralModal({
  modalVisible,
  setModalVisible,
  children,
  title,
  confirmDanger,
  headerAction,
  secondaryAction,
  noWidth,
  noMaxWidth,
  widthOverride,
  description,
  confirmText,
  cancelText,
  disableConfirm = false,
  disableCancel = false,
  onCancel,
  onConfirm,
  isLoading,
  isSubmitting,
}: Props): JSX.Element {
  const closeModal = useCallback(async () => {
    setModalVisible(false);
    if (onCancel) {
      await onCancel();
    }
  }, [setModalVisible, onCancel]);

  const confirmClicked = useCallback(() => {
    if (onConfirm) {
      try {
        if (isSubmitting) {
          setModalVisible(false);
        }
        return onConfirm();
      } catch {
        toast.error("An error occurred. Please try again later.");
      }
    } else {
      setModalVisible(false);
    }
  }, [onConfirm, isSubmitting, setModalVisible]);

  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };

  return (
    <Transition.Root show={modalVisible} as={Fragment}>
      <Dialog className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 backdrop-blur-sm bg-cool-gray-400/25 transition-opacity will-change-transform" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="flex min-h-full items-end sm:items-center justify-center">
              <Dialog.Panel
                className={`${noWidth ? "" : "sm:min-w-[480px]"} ${noMaxWidth ? "" : "max-w-[640px]"} ${
                  widthOverride ? widthOverride : ""
                } rounded flex flex-col bg-white text-left text-gray-900 relative border border-cool-gray-300 max-h-[90vh]`}
              >
                {isLoading ? <div className="absolute inset-0 w-full bg-gray-400/50" /> : null}
                <div className="py-6 px-6 space-y-6">
                  <div className={`flex`}>
                    {headerAction ? <div className="mr-2">{headerAction}</div> : null}
                    <div className="w-full flex flex-col gap-2 flex-wrap">
                      {title ? <Dialog.Title className="text-2xl font-medium leading-6">{title}</Dialog.Title> : null}
                      {description ? <Dialog.Description className="text-gray-500">{description}</Dialog.Description> : null}
                    </div>
                    <div>
                      <button
                        disabled={disableCancel}
                        autoFocus={false}
                        className="p-1 rounded-full"
                        onClick={closeModal}
                        onKeyDown={(e: any) => checkKeyDown(e)}
                      >
                        <XMarkIcon className="h-5 w-5 text-gray-500 hover:text-gray-700" />
                      </button>
                    </div>
                  </div>
                  {children ? <div className="w-full">{children}</div> : null}
                </div>
                {onConfirm ? (
                  <div className="w-full flex-col-reverse flex sm:flex-row justify-end gap-2 border-t border-gray-100 bg-cool-gray-50 py-3 px-6 rounded-b">
                    <Button type="SECONDARY" onClick={closeModal} disabled={disableCancel}>
                      {cancelText ? cancelText : "Cancel"}
                    </Button>
                    {secondaryAction ? (
                      <Button type={secondaryAction.danger ? "DANGER" : "SECONDARY"} onClick={secondaryAction.action}>
                        {secondaryAction.title}
                      </Button>
                    ) : null}
                    <Button submitting={isSubmitting} onClick={confirmClicked} disabled={disableConfirm} type={confirmDanger ? "DANGER" : "PRIMARY"}>
                      {confirmText ?? "Confirm"}
                    </Button>
                  </div>
                ) : null}
              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
