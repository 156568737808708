import { ReactNode } from "react";

export type BannerType = "WARNING" | "ERROR" | "INFO" | "NEUTRAL";

interface Props {
  children?: ReactNode;
  type: BannerType;
}

const BannerColorStyleMap: { [key in BannerType]: string } = {
  WARNING: "bg-yellow-100 border-yellow-600 text-yellow-600",
  ERROR: "bg-red-100 border-red-600 text-red-600",
  INFO: "bg-green-100 border-green-600 text-green-600",
  NEUTRAL: "bg-gray-100 border-gray-600 text-gray-600",
};

export default function Banner({ children, type }: Props): JSX.Element {
  const bannerColorStyle = BannerColorStyleMap[type];

  return <div className={`flex flex-row w-full p-2 ${bannerColorStyle} border rounded justify-center items-center`}>{children}</div>;
}
