import { FeedHeader } from "./FeedHeader";
import { FeedItems } from "./FeedItems";

export function NotificationFeed() {
  return (
    <div className="h-[480px] w-[408px]">
      <div className="h-[11%]">
        <FeedHeader />
      </div>
      <div className="h-[89%]">
        <FeedItems />
      </div>
    </div>
  );
}
