export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string }) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "account": {
    "audit_log": {
      $url: (url?: { hash?: string }) => ({ pathname: '/account/audit-log' as const, hash: url?.hash })
    },
    "settings": {
      $url: (url?: { hash?: string }) => ({ pathname: '/account/settings' as const, hash: url?.hash })
    }
  },
  "billing": {
    "edit": {
      $url: (url?: { hash?: string }) => ({ pathname: '/billing/edit' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/billing' as const, hash: url?.hash })
  },
  "copilot": {
    $url: (url?: { hash?: string }) => ({ pathname: '/copilot' as const, hash: url?.hash })
  },
  "dashboard": {
    $url: (url?: { hash?: string }) => ({ pathname: '/dashboard' as const, hash: url?.hash })
  },
  "datasources": {
    $url: (url?: { hash?: string }) => ({ pathname: '/datasources' as const, hash: url?.hash }),
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/datasources/[id]' as const, query: { id }, hash: url?.hash })
    }),
    "connect": {
      $url: (url?: { hash?: string }) => ({ pathname: '/datasources/connect' as const, hash: url?.hash })
    },
    "new": {
      _connectionType: (connectionType: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/datasources/new/[connectionType]' as const, query: { connectionType }, hash: url?.hash })
      }),
      "premium": {
        _shadowDbType: (shadowDbType: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/datasources/new/premium/[shadowDbType]' as const, query: { shadowDbType }, hash: url?.hash })
        })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/datasources/new' as const, hash: url?.hash })
    },
    "premium": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/datasources/premium/[id]' as const, query: { id }, hash: url?.hash })
      })
    }
  },
  "dataview_drafts": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/dataview-drafts/[id]' as const, query: { id }, hash: url?.hash })
    })
  },
  "dataviews": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/dataviews/[id]' as const, query: { id }, hash: url?.hash })
    }),
    "create": {
      $url: (url?: { hash?: string }) => ({ pathname: '/dataviews/create' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/dataviews' as const, hash: url?.hash })
  },
  "email": {
    "unsubscribe": {
      $url: (url?: { hash?: string }) => ({ pathname: '/email/unsubscribe' as const, hash: url?.hash })
    }
  },
  "forms": {
    "builder": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/forms/builder/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/forms/builder' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/forms' as const, hash: url?.hash })
  },
  "monitors": {
    _id: (id: string | number) => ({
      "create": {
        $url: (url?: { hash?: string }) => ({ pathname: '/monitors/[id]/create' as const, query: { id }, hash: url?.hash })
      },
      "form": {
        "confirm": {
          $url: (url?: { hash?: string }) => ({ pathname: '/monitors/[id]/form/confirm' as const, query: { id }, hash: url?.hash })
        },
        $url: (url?: { hash?: string }) => ({ pathname: '/monitors/[id]/form' as const, query: { id }, hash: url?.hash })
      },
      "runs": {
        _run_id: (run_id: string | number) => ({
          $url: (url?: { hash?: string }) => ({ pathname: '/monitors/[id]/runs/[run_id]' as const, query: { id, run_id }, hash: url?.hash })
        })
      },
      "update": {
        $url: (url?: { hash?: string }) => ({ pathname: '/monitors/[id]/update' as const, query: { id }, hash: url?.hash })
      },
      $url: (url?: { hash?: string }) => ({ pathname: '/monitors/[id]' as const, query: { id }, hash: url?.hash })
    }),
    "create": {
      $url: (url?: { hash?: string }) => ({ pathname: '/monitors/create' as const, hash: url?.hash })
    },
    "v4": {
      _id: (id: string | number) => ({
        "create": {
          $url: (url?: { hash?: string }) => ({ pathname: '/monitors/v4/[id]/create' as const, query: { id }, hash: url?.hash })
        },
        "update": {
          $url: (url?: { hash?: string }) => ({ pathname: '/monitors/v4/[id]/update' as const, query: { id }, hash: url?.hash })
        }
      })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/monitors' as const, hash: url?.hash })
  },
  "onboarding": {
    $url: (url?: { hash?: string }) => ({ pathname: '/onboarding' as const, hash: url?.hash })
  },
  "oncall": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/oncall/[id]' as const, query: { id }, hash: url?.hash })
    }),
    "create": {
      $url: (url?: { hash?: string }) => ({ pathname: '/oncall/create' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/oncall' as const, hash: url?.hash })
  },
  "open_ai_doc_gen": {
    $url: (url?: { hash?: string }) => ({ pathname: '/open-ai-doc-gen' as const, hash: url?.hash })
  },
  "playbooks": {
    "builder": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/playbooks/builder/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string }) => ({ pathname: '/playbooks/builder' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/playbooks' as const, hash: url?.hash })
  },
  "redirect_error": {
    $url: (url?: { hash?: string }) => ({ pathname: '/redirect-error' as const, hash: url?.hash })
  },
  "reports": {
    $url: (url?: { hash?: string }) => ({ pathname: '/reports' as const, hash: url?.hash })
  },
  "seeder": {
    $url: (url?: { hash?: string }) => ({ pathname: '/seeder' as const, hash: url?.hash })
  },
  "signal_views": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/signal-views/[id]' as const, query: { id }, hash: url?.hash })
    })
  },
  "signals": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/signals/[id]' as const, query: { id }, hash: url?.hash })
    }),
    "archived": {
      $url: (url?: { hash?: string }) => ({ pathname: '/signals/archived' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/signals' as const, hash: url?.hash })
  },
  "signin": {
    $url: (url?: { hash?: string }) => ({ pathname: '/signin' as const, hash: url?.hash })
  },
  "signup": {
    $url: (url?: { hash?: string }) => ({ pathname: '/signup' as const, hash: url?.hash })
  },
  "tasks": {
    $url: (url?: { hash?: string }) => ({ pathname: '/tasks' as const, hash: url?.hash })
  },
  "teams": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/teams/[id]' as const, query: { id }, hash: url?.hash })
    }),
    $url: (url?: { hash?: string }) => ({ pathname: '/teams' as const, hash: url?.hash })
  },
  "users": {
    _id: (id: string | number) => ({
      $url: (url?: { hash?: string }) => ({ pathname: '/users/[id]' as const, query: { id }, hash: url?.hash })
    })
  },
  "workflow_process_action": {
    _workflowId: (workflowId: string | number) => ({
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string }) => ({ pathname: '/workflow-process-action/[workflowId]/[id]' as const, query: { workflowId, id }, hash: url?.hash })
      }),
      "create": {
        $url: (url?: { hash?: string }) => ({ pathname: '/workflow-process-action/[workflowId]/create' as const, query: { workflowId }, hash: url?.hash })
      }
    })
  },
  "zendesk": {
    "modal": {
      $url: (url?: { hash?: string }) => ({ pathname: '/zendesk/modal' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string }) => ({ pathname: '/zendesk' as const, hash: url?.hash })
  },
  $url: (url?: { hash?: string }) => ({ pathname: '/' as const, hash: url?.hash })
}

export type PagesPath = typeof pagesPath
