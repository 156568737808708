import { Oval } from "react-loader-spinner";

export interface OvalLoaderProps {
  type?: "DEFAULT" | "SECONDARY";
  color?: undefined;
  secondaryColor?: undefined;
  size?: number;
  strokeWidth?: number;
}

export interface CustomOvalLoaderProps {
  type: "CUSTOM";
  color: string;
  secondaryColor: string;
  size: number;
  strokeWidth?: number;
}

export function OvalLoader({ type = "DEFAULT", color, secondaryColor, size = 72, strokeWidth }: OvalLoaderProps | CustomOvalLoaderProps) {
  return (
    <Oval
      color={type === "CUSTOM" ? color : type === "DEFAULT" ? "#006BE6" : "white"}
      secondaryColor={type === "CUSTOM" ? secondaryColor : type === "DEFAULT" ? "#006BE6" : "white"}
      height={size}
      width={size}
      strokeWidth={strokeWidth ? strokeWidth : undefined}
    />
  );
}
