import { useOutsideClick } from "../utils/hooks";
import OrganizationsDropdown from "./OrganizationsDropdown";
import { ChevronDoubleLeftIcon, Bars3Icon } from "@heroicons/react/24/outline";
import { useAbilities, useOrganization } from "context/OrganizationContext";
import { useSetSidebarOpen, useSidebarOpen } from "context/SidebarContext";
import Link from "next/link";
import React, { useRef } from "react";

export default function Navbar(): JSX.Element {
  const [profileDropdownOpen, setProfileDropdownOpen] = React.useState(false);
  const profileDropdownRef = useRef();
  useOutsideClick(profileDropdownRef, () => setProfileDropdownOpen(false));
  const sidebarOpen = useSidebarOpen();
  const setSidebarOpen = useSetSidebarOpen();
  const abilities = useAbilities();
  const organization = useOrganization();

  return (
    <nav className="bg-white/50 backdrop-blur-md border-b absolute left-0 right-0 top-0 z-50 border-cool-gray-300 block lg:hidden">
      <div className="px-4 mx-auto sm:px-6 lg:px-8">
        <div className="flex justify-between py-2 items-center">
          <div className="flex">
            <div className="flex items-center mr-2 -ml-2">
              {/* <!-- Mobile menu button --> */}
              <button
                className="inline-flex lg:hidden items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                aria-label="Main menu"
                aria-expanded="false"
                onClick={() => {
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                {/* <!-- Icon when menu is closed. --> */}
                {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
                {!sidebarOpen && <Bars3Icon className="w-6 h-6" />}
                {/* <!-- Icon when menu is open. --> */}
                {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
                {sidebarOpen && <ChevronDoubleLeftIcon className="w-6 h-6" />}
              </button>
            </div>
            <Link href="/" legacyBehavior>
              <div className="flex items-center flex-shrink-0 cursor-pointer">
                <img className="block w-auto h-8 lg:hidden" src={require("../public/avenue_icon_black.svg")} alt="Avenue logo" />
                <img className="hidden w-auto h-8 lg:block" src={require("../public/avenue_logo_final_black.svg")} alt="Avenue logo" />
              </div>
            </Link>
          </div>
          <div className="flex items-center">
            <div className="flex-shrink-0 space-x">
              <div className="inline-block">
                <OrganizationsDropdown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
