import { SideBarItemType, SidebarLinkData, SidebarSectionData, SubSidebarItemData } from "./types";
import {
  BellIcon,
  CubeTransparentIcon,
  CircleStackIcon,
  DocumentChartBarIcon,
  FolderOpenIcon,
  ShieldCheckIcon,
  UserGroupIcon,
  UserCircleIcon,
  SparklesIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { Role } from "@prisma/client";
import { useOrganization, useMembership } from "context/OrganizationContext";
import { useUser } from "context/UserContext";
import { pagesPath } from "utils/$path";
import { trpc } from "utils/trpc";

/**
 * Fetches process views and adds it to the sidebar sections
 */
export default function useSidebarSections(): SidebarSectionData[] {
  const organization = useOrganization();
  const user = useUser();
  const membership = useMembership();
  const role = membership?.role;

  const { data: signalViews, isLoading } = trpc.processViews.list.useQuery();

  const signalViewSidebarItems: SubSidebarItemData[] | undefined = signalViews?.map((signalView) => ({
    href: `/signal-views/${signalView.id}`,
    title: signalView.title,
    type: SideBarItemType.LINK,
  }));

  const signalViewFavoriteSidebarItems: SubSidebarItemData[] | undefined = signalViews
    ?.filter((signalView) => signalView.favorite)
    .map((signalView) => ({
      href: `/signal-views/${signalView.id}`,
      title: signalView.title,
      type: SideBarItemType.LINK,
    }));

  const formsLink: SidebarLinkData = {
    href: pagesPath.forms.$url().pathname,
    title: "Forms",
    icon: CubeTransparentIcon,
    type: SideBarItemType.LINK,
  } as SidebarLinkData;

  const monitorsSection: SidebarSectionData = {
    sectionTitle: "Observability",
    items: [
      { href: "/monitors", title: "Monitors", icon: ShieldCheckIcon, type: SideBarItemType.LINK },
      { href: "/dataviews", title: "Data Views", icon: FolderOpenIcon, type: SideBarItemType.LINK },
      { href: "/datasources", title: "Data Sources", icon: CircleStackIcon, type: SideBarItemType.LINK },
      ...(membership?.role === Role.CREATOR || membership?.role === Role.CUSTOMER_ENGINEER || membership?.role === Role.ADMIN // Only show Forms if the feature flag is enabled and the logged in user is either a creator or an admin
        ? [formsLink]
        : []),
    ],
  };

  const tasksDashboardLink: SidebarLinkData = {
    href: pagesPath.tasks.$url().pathname,
    title: "Tasks",
    icon: ClipboardDocumentListIcon,
    type: SideBarItemType.LINK,
  };

  const monitorWizardLink: SidebarLinkData = {
    href: pagesPath.copilot.$url().pathname,
    title: "Copilot",
    icon: SparklesIcon,
    type: SideBarItemType.LINK,
  };

  const workstreamsSection: SidebarSectionData = {
    sectionTitle: "Workstreams",
    items: [
      ...(organization?.dashboardEnabled
        ? [{ href: "/dashboard", title: "My Dashboard", icon: UserCircleIcon, type: SideBarItemType.LINK } as SidebarLinkData]
        : []),
      tasksDashboardLink,
      { href: "/signals", title: "Signals", icon: BellIcon, type: SideBarItemType.LINK },
      ...(role !== Role.GUEST ? [monitorWizardLink] : []),
    ],
  };

  const organizationSection: SidebarSectionData = {
    sectionTitle: "Organization",
    items: [
      { href: "/teams", title: "Teams", icon: UserGroupIcon, type: SideBarItemType.LINK },
      { href: "/reports", title: "Reports", icon: DocumentChartBarIcon, type: SideBarItemType.LINK },
    ],
  };

  if (!isLoading) {
    const signalsPageCandidates = workstreamsSection.items.filter((item) => item.title === "Signals" && item.type === SideBarItemType.LINK);
    if (signalsPageCandidates.length === 1) {
      const signalsPage = signalsPageCandidates[0] as SidebarLinkData;
      signalsPage.subItems = signalViewFavoriteSidebarItems;
    }
  }

  return [
    // Only return sections if the user is authorized to view the section
    ...(role !== Role.GUEST && role !== Role.MEMBER ? [monitorsSection] : []), // Only show monitors if the user is not a guest or member
    workstreamsSection,
    ...(role !== Role.GUEST ? [organizationSection] : []),
  ];
}
