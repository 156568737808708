import { Popover } from "@headlessui/react";
import React, { ReactNode, useRef } from "react";

interface Props {
  buttonCharacter?: string;
  children: ReactNode;
}

export default function PopoverTooltip({ buttonCharacter = "?", children }: Props): JSX.Element {
  let referenceElementRef = useRef(null);
  let popperElementRef = useRef(null);

  return (
    <Popover className="inline-block relative">
      <Popover.Button ref={referenceElementRef} className="relative ml-1 rounded-full flex justify-center items-center text-white bg-primary-main h-4 w-4">
        <span className="text-xs">{buttonCharacter}</span>
      </Popover.Button>
      <Popover.Panel
        className="absolute z-10 bg-primary-light flex justify-center items-center shadow-md rounded-md leading-normal text-sm top-1 -translate-y-full translate-x-5"
        ref={popperElementRef}
      >
        {children}
      </Popover.Panel>
    </Popover>
  );
}
