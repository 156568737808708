import { NotificationFeed } from "./NotificationFeed";
import { Ellipse } from "@avenueops/web-components";
import { InboxIcon } from "@heroicons/react/24/outline";
import * as Popover from "@radix-ui/react-popover";
import { trpc2 } from "utils/trpc";

const INBOX_REFETCH_INTERVAL = 10000;

export function NotificationInbox() {
  const { data: unreadNotificationCount } = trpc2.v2.inbox.countInboxNotifications.useQuery({ read: false }, { refetchInterval: INBOX_REFETCH_INTERVAL });
  const hasUnreadNotifications: boolean = unreadNotificationCount ? unreadNotificationCount > 0 : false;

  return (
    <Popover.Root>
      <Popover.Trigger>
        <div>
          <Ellipse active={hasUnreadNotifications} size="LARGE" align="RIGHT">
            <div className="rounded-full bg-cool-gray-200 p-1.5 cursor-pointer hover:bg-cool-gray-300">
              <InboxIcon className="h-5 w-5 text-gray-900" />
            </div>
          </Ellipse>
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content sideOffset={13} align="center" className="bg-white rounded shadow-md" side="right" collisionPadding={10}>
          <NotificationFeed />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}
