import { useState } from "react";

/**
 * useState but backed up to local storage
 */
export default function useLocalStorageState<T>(key: string, defaultValue: T): [T, (_: T) => void, () => void] {
  const [value, setValue] = useState<T>(localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)!)?.value : defaultValue);
  const setValueWrapper = (newValue: T) => {
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify({ value: newValue }));
  };

  const clearValue = () => {
    localStorage.removeItem(key);
  };

  return [value, setValueWrapper, clearValue];
}
