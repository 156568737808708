import get from "../../utils/get";
import { useOutsideClick } from "../../utils/hooks";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
// import { Picker } from "emoji-mart";
// import "emoji-mart/css/emoji-mart.css";
import React, { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface Props {
  name: string;
  control: any;
  hideLabel?: boolean;
  required?: boolean;
  trigger?: React.ReactNode;
}

export default function EmojiPicker({ control, name, hideLabel, required, trigger }: Props): JSX.Element {
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const emojiPickerRef = useRef(null);
  useOutsideClick(emojiPickerRef, () => setShowEmojiPicker(false));

  const { formState } = useFormContext();
  const { errors } = formState;

  const error = errors && get(errors, name);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required }}
      render={({ field: { value, onChange } }) => (
        <div ref={emojiPickerRef}>
          {!hideLabel ? <label className="block text-sm font-medium leading-5 text-gray-700">Emoji</label> : null}
          {trigger ? (
            <div onClick={() => setShowEmojiPicker(true)} onMouseDown={(e) => e.preventDefault()}>
              {trigger}
            </div>
          ) : (
            <select
              className={`hover:bg-gray-100 block w-full min-w-max
                ${error ? "border-red-200 outline-none ring ring-red-200 ring-opacity-50" : ""}
                ${!hideLabel ? "mt-1" : ""}
                text-base leading-6 border-gray-300 rounded form-select
                focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-blue-200 focus:border-blue-300`}
              onClick={() => setShowEmojiPicker(true)}
              onMouseDown={(e) => e.preventDefault()}
            >
              <option>{value}</option>
            </select>
          )}
          {showEmojiPicker && (
            <div className={"absolute mt-2 z-20"}>
              <Picker
                data={data}
                onEmojiSelect={(emoji: any) => {
                  onChange(emoji.native);
                  setShowEmojiPicker(false);
                }}
                theme={"light"}
              />
            </div>
          )}
        </div>
      )}
    />
  );
}
