import type { OrganizationContext } from "@avenueops/shared-types";
import { ClientOrganizationMembership } from "@avenueops/shared-types";
import { BillingPlan, Organization } from "@prisma/client";
import axios from "axios";
import React from "react";
import { OvalLoader } from "@avenueops/web-components";
import { useQuery } from "@tanstack/react-query";
import { AvenueAbility, getUserAbilities } from "@avenueops/frontend-shared";

// TODO: Use membership context instead of Organization context
const OrganizationContext = React.createContext<OrganizationContext | undefined>(undefined);

function OrganizationProvider(props: any) {
  const { isLoading, error, data } = useQuery(["organization"], async () => {
    const response = await axios.get("/organizations/active");
    return response.data;
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen h-available bg-cool-gray-100">
        <OvalLoader type="DEFAULT" size={72} />
      </div>
    );
  }

  if (error) {
    const anyTypedError: any = error;
    if (anyTypedError.message) {
      return <span>An error has occurred: {anyTypedError.message}</span>;
    } else {
      return <span>An error has occurred: {JSON.stringify(anyTypedError)}</span>;
    }
  }

  return <OrganizationContext.Provider value={data} {...props} />;
}

function useOrganization(): Organization | undefined {
  const context = React.useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(`useOrganization must be used within a OrganizationProvider`);
  }
  return context?.organization;
}

function useMembership(): ClientOrganizationMembership | undefined {
  const context = React.useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(`useMembership must be used within a OrganizationProvider`);
  }
  return context?.membership;
}

function useBillingPlan(): BillingPlan | undefined {
  const context = React.useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(`useMembership must be used within a OrganizationProvider`);
  }
  return context?.billingPlan;
}

function useAbilities(): AvenueAbility {
  const context = React.useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error(`useAbilities must be used within a OrganizationProvider`);
  }

  // There are no explicit abilities based on resources in the frontend
  return getUserAbilities(context?.membership?.role, []);
}

export { OrganizationProvider, useOrganization, useMembership, useAbilities, useBillingPlan };
