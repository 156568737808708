import clsx from "clsx";
import React, { ComponentType, ForwardedRef, ReactNode } from "react";
import { AriaButtonProps, useButton } from "react-aria";
import { OvalLoader } from "../../display/OvalLoader";

export interface ButtonProps {
  id?: string;
  onClick?: (() => void) | React.MouseEventHandler;
  type?: "PRIMARY" | "SECONDARY" | "TERTIARY" | "QUARTERNARY" | "DANGER" | "DANGER_SECONDARY" | "TEXT";
  size?: "SMALL" | "MEDIUM" | "LARGE";
  noPadding?: boolean;
  full?: boolean;
  LeadIcon?: React.FC<any>;
  TailIcon?: React.FC<any>;
  submit?: boolean;
  disabled?: boolean;
  submitting?: boolean;
  formHtmlId?: string;
}

const Button = React.forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
  (
    {
      id,
      onClick,
      disabled,
      type = "PRIMARY",
      size = "LARGE",
      noPadding,
      LeadIcon,
      TailIcon,
      full,
      submit,
      children,
      submitting,
      formHtmlId,
    }: React.PropsWithChildren<ButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const internalRef = React.useRef<HTMLButtonElement | null>(null);
    const loader = <OvalLoader type={type === "SECONDARY" ? "DEFAULT" : "SECONDARY"} size={20} />;

    return (
      <button
        ref={(element) => {
          internalRef.current = element;
          if (typeof ref === "function") {
            ref(element);
          } else if (ref) {
            ref.current = element;
          }
        }}
        onClick={(e) => {
          onClick?.(e);
        }}
        disabled={disabled}
        type={submit ? "submit" : "button"}
        form={formHtmlId}
        className={clsx(
          "rounded flex flex-row truncate justify-center items-center focus:outline-none whitespace-nowrap appearance-none disabled:cursor-not-allowed",
          type === "PRIMARY" &&
            !disabled &&
            "bg-primary-main border-primary-main hover:bg-primary-dark hover:border-primary-dark border text-white focus:bg-primary-main focus:border-primary-main focus:border-opacity-50 ",
          type == "TERTIARY" &&
            !disabled &&
            "bg-gradient-to-b from-gray-50 hover:from-gray-100 hover:to-gray-100 to-gray-100 text-gray-950 border  border-gray-100 focus:border-primary-main focus:border-opacity-50 ",
          type === "SECONDARY" &&
            !disabled &&
            "text-gray-950 border border-gray-300 hover:bg-gray-50 focus:border-2 focus:border-primary-main focus:border-opacity-50",
          type == "QUARTERNARY" &&
            !disabled &&
            "bg-yellow-700 border border-yellow-700 hover:bg-yellow-800 hover:border-yellow-800 focus:border-yellow-800 focus:bg-yellow-700 text-gray-950",
          type === "DANGER" && !disabled && "bg-red-700 hover:bg-red-800 text-gray-50",
          type === "DANGER_SECONDARY" && !disabled && "border border-gray-50 bg-transparent text-red-700 hover:text-red-800 focus:border-red-800",
          type === "TEXT" && !disabled && "text-gray-950 hover:text-primary-main",
          disabled && type === "TEXT" && "text-gray-600 text-opacity-25",
          disabled && type !== "SECONDARY" && type !== "TEXT" && "bg-gray-50 bg-opacity-50 text-gray-600 text-opacity-25",
          disabled && type === "SECONDARY" && "border border-gray-100 text-gray-600 text-opacity-25",
          size === "LARGE" && "h-[40px] text-base font-medium py-2 px-4 gap-2",
          size === "MEDIUM" && "h-[32px] text-base font-medium py-[3px] px-4 gap-2",
          size === "SMALL" && "h-[28px] text-sm font-medium px-[18px] py-[5px] gap-1",
          noPadding && "!p-0",
          full && "w-full",
        )}
      >
        {submitting ? (
          loader
        ) : (
          <>
            {LeadIcon ? <LeadIcon className={`${size === "SMALL" ? "h-3 w-3" : "h-5 w-5"} shrink-0`} /> : null}
            {children}
            {TailIcon ? <TailIcon className={`${size === "SMALL" ? "h-3 w-3" : "h-5 w-5"} shrink-0`} /> : null}
          </>
        )}
      </button>
    );
  },
);

export default Button;
