import { MutationOptions } from "./utils";
import { toast } from "react-toastify";
import { toastError } from "utils/toast";
import { trpc, trpc2 } from "utils/trpc";

function useProcessMutationInvalidators() {
  const utils = trpc.useContext();
  const utils2 = trpc2.useContext();

  return async () => {
    await utils.processes.get.invalidate();
    await utils.processes.list.invalidate();
    await utils.processes.countByGroup.invalidate();
    await utils.processes.getEvents.invalidate();
    await utils.processes.getProcessListAggregates.invalidate();
    await utils2.v2.workflows.mute.invalidate();
    await utils2.v2.workflows.processes.invalidate();
    await utils2.v2.workflows.analytics.invalidate();
  };
}

export function useSetStatusMutation(opts?: MutationOptions) {
  const { onSuccess, onError } = opts ?? {};
  const invalidators = useProcessMutationInvalidators();

  return trpc.processes.setStatus.useMutation({
    onSuccess: async () => {
      await invalidators();
      onSuccess ? await onSuccess() : null;
    },
    onError: (error: any) => {
      toastError(error, "There was an error updating the signal status. Please try again later.");
      onError ? onError() : null;
    },
  });
}

export function useBulkSetStatusMutation(opts?: MutationOptions) {
  const { onSuccess, onError } = opts ?? {};
  const invalidators = useProcessMutationInvalidators();

  return trpc.bulkActions.setSignalsStatus.useMutation({
    onSuccess: async () => {
      await invalidators();
      onSuccess ? await onSuccess() : null;
      toast.success("The status was updated!");
    },
    onError: async (error: any) => {
      toastError(error, "There was an error updating the status of the selected signals. Please try again later.");
      onError ? await onError() : null;
    },
  });
}

export function useEscalateProcessMutation(opts?: MutationOptions) {
  const { onSuccess, onError } = opts ?? {};
  const invalidators = useProcessMutationInvalidators();

  return trpc.escalation.escalateByProcessId.useMutation({
    onSuccess: async () => {
      await invalidators();
      toast.success("The signal was successfully escalated!");
      onSuccess ? await onSuccess() : null;
    },
    onError: async (error: any) => {
      toastError(error, "There was an error escalating to the next escalation group.");
      onError ? await onError() : null;
    },
  });
}

export function useAssignProcessMutation(opts?: MutationOptions) {
  const { onSuccess, onError } = opts ?? {};
  const invalidators = useProcessMutationInvalidators();

  return trpc.processes.assignUser.useMutation({
    onSuccess: async () => {
      await invalidators();
      toast.success("The assignee was updated!");
      onSuccess ? await onSuccess() : null;
    },
    onError: async (error: any) => {
      toastError(error, "There was an error assigning the signal. Please try again later.");
      onError ? await onError() : null;
    },
  });
}

export function useBulkAssignProcessMutation(opts?: MutationOptions) {
  const { onSuccess, onError } = opts ?? {};
  const invalidators = useProcessMutationInvalidators();

  return trpc.bulkActions.assignSignals.useMutation({
    onSuccess: async () => {
      await invalidators();
      toast.success("The assignee was updated!");
      onSuccess ? await onSuccess() : null;
    },
    onError: async (error: any) => {
      toastError(error, "There was an error assigning the signal(s). Please try again later.");
      onError ? await onError() : null;
    },
  });
}

export function useUpdateProcessesArchiveStatusMutation(opts?: MutationOptions) {
  const { onSuccess, onError } = opts ?? {};
  const invalidators = useProcessMutationInvalidators();

  return trpc.processes.updateArchiveStatus.useMutation({
    onSuccess: async (res, { archive }) => {
      await invalidators();
      const archiveStr = archive ? "archived" : "unarchived";
      const plural = res > 1 || res === 0 ? "s" : "";
      toast.success(`${res} signal${plural} ${archiveStr} successfully!`);
      onSuccess ? await onSuccess() : null;
    },
    onError: async (error, { archive }) => {
      const archiveStr = archive ? "archiving" : "unarchiving";
      toastError(error, `There was an error ${archiveStr} the signal(s). Please try again later.`);
      onError ? await onError() : null;
    },
  });
}

export function useSetPriorityProcessMutation(opts?: MutationOptions) {
  const { onSuccess, onError } = opts ?? {};
  const invalidators = useProcessMutationInvalidators();

  return trpc.processes.setPriority.useMutation({
    onSuccess: async () => {
      await invalidators();
      onSuccess ? await onSuccess() : null;
    },
    onError: (error: any) => {
      toastError(error, "There was an error updating the signal priority. Please try again later.");
      onError ? onError() : null;
    },
  });
}

export function useBulkSetPriorityMutation(opts?: MutationOptions) {
  const { onSuccess, onError } = opts ?? {};
  const invalidators = useProcessMutationInvalidators();

  return trpc.bulkActions.setSignalsPriority.useMutation({
    onSuccess: async () => {
      await invalidators();
      onSuccess ? await onSuccess() : null;
      toast.success("The priority was updated!");
    },
    onError: (error: any) => {
      toastError(error, "There was an error updating the priority of the selected signals. Please try again later.");
      onError ? onError() : null;
    },
  });
}
